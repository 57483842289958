import { FC, Fragment } from 'react';
import { Tooltip } from 'antd';
import { IProjectFolderDetails } from 'models/interface';
import { ConstantRowTypeEnum } from 'models/enums/constants';

export const DisplayRowName: FC<{
  row: IProjectFolderDetails;
  displayType: boolean;
  rowClickHandler: any;
  rowName: string;
}> = ({ row, displayType, rowClickHandler, rowName }) => {
  return (
    <Fragment>
      {displayType ? (
        <span
          className="itemName"
          onClick={() => rowClickHandler(row)}
          onKeyDown={() => {}}
        >
          {rowName}
        </span>
      ) : (
        <Tooltip title={row.row_name} color={'#2E364C'} placement="bottom">
          <span
            className="itemName"
            onClick={() => rowClickHandler(row)}
            onKeyDown={() => {}}
          >
            {rowName}
          </span>
        </Tooltip>
      )}
    </Fragment>
  );
};
