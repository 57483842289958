export enum NavRoutesEnum {
  ACCOUNT_ACTIVATION = '/user/account-activation',
  SIGNUP_SUCCESS = '/user/signup-success',
  USER_ONBOARDING = '/user/onboarding',
  CREATE_NEW_TEAM = '/user/create-new-team',
  PLANNER = 'planner',
  USER_SETTINGS = 'users/settings',
  // Organization pages
  DASHBOARD = 'dashboard',
  DASHBOARD_PROJECT_LIST = 'project/list',
  DASHBOARD_PROJECT_DETAILS = 'project/:projectId',
  NEW_DASHBOARD_PROJECT_DETAILS = 'project-v2/:projectId',
  MESSAGES = 'messages',
  ERROR_PAGE = '/error',
  LOGIN_REDIRECT = '/login-redirect',
  //
  USER_STORY_GENERATOR = '/user-story-generator',
  EPIC_STORY_GENERATOR = '/epic-story-generator',
  ARCHIVE_EPIC = '/archive-epic',
}
