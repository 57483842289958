import { type TableProps } from 'antd';
import ReactDragListView from 'react-drag-listview';
import RowDragTable from './RowDragTable';

interface CTableProps extends TableProps<any> {
  dataSource: any;
  onColumnOrderChange?: (columns: any, fromKey: string, toKey: string) => void;
  onRowReOrder?: (dataSource: any) => void;
  onClickAdd?: () => void;
  disabled?: boolean;
}

const TableView = (props: CTableProps) => {
  const visibleColumns = [
    {
      key: 'sort',
      fixed: true,
      width: 30,
    },
    ...(props?.columns?.filter((i: any) => !i?.hidden) ?? []).map((i: any) => ({
      ...i,
      title: <span className="dragHandler">{i?.title ?? ''}</span>,
    })),
  ];

  return (
    <>
      <ReactDragListView.DragColumn
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle"
        onDragEnd={(fromIndex, toIndex) => {
          const currentFrom = visibleColumns[fromIndex];
          const currentTo = visibleColumns[toIndex];

          props.onColumnOrderChange?.([], currentFrom?.key, currentTo?.key);
        }}
      >
        <RowDragTable {...props} columns={visibleColumns} />
      </ReactDragListView.DragColumn>
    </>
  );
};

export default TableView;
