import { create } from 'zustand';
import {
  ICustomizeSectionUpdateAttributes,
  IGridList,
  IGridListAttributes,
  IMemopad,
  IMemopadAttributes,
  IProjectData,
  IProjectMember,
  IProjectMembersQueryParams,
  IProjectState,
  ITaskList,
  ITaskListAttributes,
} from '../models/interface';
import projectService from 'services/ProjectService/project.service';
import { IPackDetail } from 'models/interface/customization.interface';
import BrowserStorageService from 'services/browserStorage.service';
import { CustomFieldCategory } from 'models/interface/customField.interface';

export const useProjectDashboardStore = create<IProjectState>((set) => ({
  //initial states
  projectDetails: null,
  taskListCmsData: null,
  memopadCmsData: null,
  taskStatusMaster: null,
  taskPriorityMaster: null,
  isLoadingMasterData: false,
  projectMembers: null,
  initiateProjectMembers: null,
  gridListCmsData: null,
  showSectionDeleteConfirmation: false,
  customizeSectionUpdateCmsData: null,
  packList: null,
  sectionDetail: null,
  messagingCmsData: null,
  selectedTemplate: null,
  showSidePanel: false,
  refreshDashboardFolders: false,
  customFields: null,
  isTaskEmpty: true,
  customfieldCmsData: null,
  projectActiveTab: 0,
  //actions
  setProjectDetails: (value: IProjectData | null) =>
    set({ projectDetails: value }),
  setTaskListCmsData: (value: ITaskListAttributes | null) =>
    set({ taskListCmsData: value }),
  setMemopadCmsData: (value: IMemopadAttributes | null) =>
    set({ memopadCmsData: value }),
  fetchTaskStatusMaster: async () => {
    set({ isLoadingMasterData: true });
    const response = await projectService.getStatusMaster();
    set({ taskStatusMaster: response.data });
    set({ isLoadingMasterData: false });
  },
  fetchTaskPriorityMaster: async () => {
    set({ isLoadingMasterData: true });
    const response = await projectService.getPriorityMaster();
    set({ taskPriorityMaster: response.data });
    set({ isLoadingMasterData: false });
  },
  fetchProjectMembers: async (queryParams: IProjectMembersQueryParams) => {
    set({ isLoadingMasterData: true });
    const response = await projectService.getProjectMembers(queryParams);
    const browserStorage = BrowserStorageService.getInstance();
    let sortedMembers: IProjectMember[] | null = null;
    if (response.data.member.length > 0) {
      const activeUsers = response.data.member.filter(
        (ele: IProjectMember) => !ele.inactive,
      );
      const inactiveUsers = response.data.member.filter(
        (ele: IProjectMember) => ele.inactive,
      );
      let sortedActiveUsers: any = [],
        sortedInactiveUsers: any = [];
      if (activeUsers.length > 0) {
        sortedActiveUsers = activeUsers.sort((a, b) =>
          a.full_name!.localeCompare(b.full_name!),
        );
      }
      if (inactiveUsers.length > 0) {
        sortedInactiveUsers = inactiveUsers.sort((a, b) =>
          a.email!.localeCompare(b.email!),
        );
      }
      sortedMembers = [...sortedActiveUsers, ...sortedInactiveUsers];
    }
    browserStorage.setProjectMembers(JSON.stringify(sortedMembers));
    set({
      projectMembers: sortedMembers,
      initiateProjectMembers: sortedMembers,
    });
    set({ isLoadingMasterData: false });
  },
  setGridListCmsData: (value: IGridListAttributes | null) =>
    set({ gridListCmsData: value }),
  setShowSectionDeleteConfirmation: (value: boolean) =>
    set({ showSectionDeleteConfirmation: value }),
  setCustomizeSectionUpdateCmsData: (
    value: ICustomizeSectionUpdateAttributes | null,
  ) => set({ customizeSectionUpdateCmsData: value }),
  setPackList: (value: IPackDetail[] | null) => set({ packList: value }),
  setSectionDetail: (value: ITaskList | IGridList | IMemopad | null) =>
    set({ sectionDetail: value }),
  setMasterDataLoading: (value: boolean) => set({ isLoadingMasterData: value }),
  setMessagingCmsData: (value: any) => set({ messagingCmsData: value }),
  setSelectedTemplate: (value: any) => set({ selectedTemplate: value }),
  setShowSidePanel: (value: boolean) => set({ showSidePanel: value }),
  setRefreshDashboardFolders: (value: boolean) =>
    set({ refreshDashboardFolders: value }),
  setCustomFields: (value: CustomFieldCategory[]) =>
    set({ customFields: value }),
  setCustomfieldCmsData: (value: any) => set({ customfieldCmsData: value }),
  setProjectActiveTab: (value: string | number) =>
    set({ projectActiveTab: value }),
}));
