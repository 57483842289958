import { FC, Fragment, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import TaskNameInputField from './TaskNameInputField';
import {
  IGridList,
  IGridListTaskDetail,
  IProjectTemplate,
} from 'models/interface';
import MessageThread from '../../MessageThread';
import { SectionTypeMessages } from 'models/enums/messages';

const TaskList: FC<{
  sectionDetails: IGridList;
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, templateDetails }) => {
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();
  const isTaskDone = (taskDetails: IGridListTaskDetail) =>
    taskDetails.total_block_count !== 0 &&
    taskDetails.completed_block_count === taskDetails.total_block_count;

  const isItemToDelete = (taskDetails: IGridListTaskDetail) => {
    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === 'task') {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        taskDetails.task_id
      );
    }

    return false;
  };

  return (
    <Fragment>
      {sectionDetails.grid_list_details?.task_details?.map(
        (ele: IGridListTaskDetail, index: number) => (
          <Draggable
            key={ele.task_id}
            draggableId={`${ele.task_id}`}
            index={index}
            isDragDisabled={true}
          >
            {(provided: any) => {
              return (
                <li
                  className={`${
                    isTaskDone(ele) ? 'doneLeftProgressWrap' : ''
                  } ${isItemToDelete(ele) ? 'deleteTaskHighlight' : ''}`}
                  key={ele.task_id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <TaskNameInputField
                    taskDetails={ele}
                    sectionDetails={sectionDetails}
                  />
                  {!ele?.isNew && (
                    <Fragment>
                      <MessageThread
                        sectionId={sectionDetails.section_id}
                        taskId={ele.task_id}
                        sectionType={SectionTypeMessages.GRID_LIST_TASK}
                        threadName={ele.task_name}
                        sectionName={sectionDetails.section_name!}
                        taskDetails={ele}
                        templateDetails={templateDetails}
                        setOpenMessageExpandedView={setOpenMessageExpandedView}
                        index={index}
                        setExpandedViewForMessageIndex={
                          setExpandedViewForMessageIndex
                        }
                      />
                    </Fragment>
                  )}
                  {!ele?.isNew && /*<TaskMessageThread />*/ null}
                  {!ele?.isNew && (
                    <Fragment>
                      {/* <TaskProgressBar taskDetails={ele} /> */}
                      {/* <Rbac allowedPermissions={[ERbacPermissions.PROJECT_VIEW_ALL]}>
                      <TaskDelete
                        taskDetails={ele}
                        sectionDetails={sectionDetails}
                      />
                    </Rbac> */}
                    </Fragment>
                  )}
                </li>
              );
            }}
          </Draggable>
        ),
      )}
      {/* <Rbac allowedPermissions={[ERbacPermissions.PROJECT_VIEW_ALL]}>
        <AddTaskCTA sectionDetails={sectionDetails} />
      </Rbac> */}
    </Fragment>
  );
};

export default TaskList;
