import { MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { ConfigProvider, message, Popover, Tooltip } from 'antd';
import enUS from 'antd/locale/en_US';
import frFR from 'antd/locale/fr_FR';
import jaJP from 'antd/locale/ja_JP';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import dayjs from 'dayjs';
import { newCustomFieldColumnWidth } from 'models/enums/constants';
import {
  ICustomFieldModalAttributes,
  IProjectState,
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'models/interface';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import BrowserStorageService from 'services/browserStorage.service';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { checkIsCustomField } from '../../GridListV4/services/grid.handler';
import useTableService, {
  DEFAULT_COLUMN_DATA_INDEX,
  mappingDetails,
} from '../services/table.service';
import useTaskService from '../services/task.service';
import Task from '../Task/Task';
import AddCustomField from './AddCustomField';
import ActionDropdown from './ColumnAction/ActionDropdown';
import UploadField from './CustomCells/UploadField';
import PriorityDropdown from './CustomFieldCells/PriorityDropdown';
import HideColumns from './Filter/HideColumns';
import TableView from './TableComponents/ColumnDragTable';
import CustomNumberField from './CustomCells/CustomNumberField';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import cryptoRandomString from 'crypto-random-string';
import { Locale } from 'antd/es/locale';
import MultiSelectDropdown from './CustomFieldCells/MultiSelectDropdown';
import CustomTextField from './CustomCells/CustomTextField';
import DateTimeField from './CustomCells/DateTimeField';
export interface DataType extends ITaskListDetails {
  key: React.Key;
  id: string | number;
  dataIndex: string;
  name: string;
  platform: string;
  version: string;
  label: string;
  title: string;
  upgradeNum: number;
  creator: string;
  createdAt: string;
  show: boolean;
  options?: any[];
  fixed: boolean;
  hidden: boolean;
  columnIndex: number;
  isDefaultColumn: boolean;
  width: number;
}

export interface ExpandedDataType {
  key: React.Key;
  date: string;
  name: string;
  upgradeNum: string;
}

const localeMap: {
  [key: string]: Locale; // Allow any string as a key
  en: Locale;
  fr: Locale;
  ja: Locale;
} = {
  en: enUS,
  fr: frFR,
  ja: jaJP,
};

const CTable: React.FC<{
  taskDetails?: ITaskList;
  sectionToggleState: 'collapsed' | 'expanded';
  socket?: any;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  isTaskEmpty?: boolean;
  customfieldCmsData?: ICustomFieldModalAttributes;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const {
    taskDetails,
    setTaskListDetails,
    isTaskEmpty,
    setIsTaskEmpty,
    customfieldCmsData,
  } = props ?? {};
  const location = useLocation();
  const browserStorage = BrowserStorageService.getInstance();
  const language = browserStorage.getLocalization().language;

  const { fetchNewPermission } = useFetchNewPermission();

  // Stores
  const { taskListCmsData, customFields, projectDetails } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  // Static
  const sectionId = taskDetails?.section_id ?? '';
  const packId = taskDetails?.pack_id;
  const sectionName = taskDetails?.section_name ?? '';
  const org_key = location.pathname.split('/')[2];
  const [status, task, dueDate, assignee] = DEFAULT_COLUMN_DATA_INDEX;
  const DEFAULT_COLUMNS: any = [];
  const ACTION_COLUMN = {
    title: taskListCmsData?.lbl_task_action_header,
    hidden: false,
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 60,
    fixed: 'right',
    render: (_: any, record: any) => {
      return (
        <Task
          setIsTaskEmpty={setIsTaskEmpty}
          key={record.task_id}
          eachTaskDetail={record}
          sectionId={sectionId}
          setTaskListDetails={props?.setTaskListDetails}
          statusDoneId={taskDetails?.status_done_id}
          sectionName={sectionName}
          packId={packId}
          taskDetails={props?.taskDetails}
          renderType="action"
        />
      );
    },
  };

  // States
  const [columns, setColumns] = useState<TableColumnsType<DataType>>([]);
  const [dataSource, setDataSource] = useState<ITaskListDetails[]>();
  let deleteOptionsRef = useRef<any>(null);

  // Services
  const {
    updateExistingTask,
    updateCustomField,
    deleteCustomFieldAPI,
    updateSettings,
  } = useTaskService({
    projectDetails,
    sectionId: taskDetails?.section_id ?? 0,
    org_key,
    userDetails,
    taskListCmsData,
  });
  const {
    revertCustomField,
    addCustomField,
    getDataSource,
    convertColumnsToCustomMeta,
    deleteCustomField,
    changeCustomField,
    handleToggleEdit,
    updateCFData,
  } = useTableService({
    taskDetails,
    setTaskListDetails,
  });

  const composeColumns = (newCols: any) => {
    const columnList = [...newCols];
    if (!projectDetails?.is_archived) {
      columnList.push(ACTION_COLUMN);
    }
    setColumns(columnList);
  };

  // Functions
  const getColumns = (taskDetail?: ITaskList) => {
    const metaData = (taskDetail?.custom_meta ?? [])
      .map((i: any, index) => {
        if (!i) return;

        if (+i.mapping_id >= 1 && +i.mapping_id <= 4) {
          if (+i.mapping_id === 1) {
            return {
              mapping_id: i.mapping_id,
              title: taskListCmsData?.lbl_task_status_header,
              width: i.width,
              hidden: i.hidden,
              dataIndex: status,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: status,
              fixed: 'left',
              align: 'center',
              sorter: (a: any, b: any) =>
                (a?.[status] ?? 0) - (b?.[status] ?? 0),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    // socket={props?.socket}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="status"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 2) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <Popover
                  content={<div>Actions</div>}
                  trigger="contextMenu"
                  placement="bottomLeft"
                >
                  <span style={{ width: '100%' }}>
                    {taskListCmsData?.lbl_task_name_header}
                  </span>
                </Popover>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: task,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: task,
              fixed: 'left',
              sorter: (a: any, b: any) =>
                (a?.[task] ?? '').localeCompare(b?.[task] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="task"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 3) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_task_due_date_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: dueDate,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: dueDate,
              sorter: (a: any, b: any) =>
                dayjs(a?.[dueDate]).diff(dayjs(b?.[dueDate])),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="dueDate"
                  />
                );
              },
            };
          }

          if (+i.mapping_id === 4) {
            return {
              mapping_id: i.mapping_id,
              title: (
                <span className="displayFlex">
                  {taskListCmsData?.lbl_task_assignee_header}
                </span>
              ),
              width: i.width,
              hidden: i.hidden,
              dataIndex: assignee,
              isDefaultColumn: true,
              showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
              key: assignee,
              sorter: (a: any, b: any) =>
                (a?.[assignee] ?? '').localeCompare(b?.[assignee] ?? ''),
              render: (_: any, record: any) => {
                return (
                  <Task
                    setIsTaskEmpty={setIsTaskEmpty}
                    key={record.task_id}
                    eachTaskDetail={record}
                    sectionId={sectionId}
                    setTaskListDetails={setTaskListDetails}
                    statusDoneId={taskDetail?.status_done_id}
                    sectionName={sectionName}
                    packId={packId}
                    taskDetails={taskDetail}
                    renderType="assignee"
                  />
                );
              },
            };
          }
        } else {
          let column = {
            ...i,
            title: (
              <div className="custom-column-actions">
                <span className="custom-column-name">
                  <Tooltip
                    title={i?.description ? i?.description : i?.name}
                    color={'#2E364C'}
                  >
                    {i?.name}
                  </Tooltip>
                </span>
                {!projectDetails?.is_archived ? (
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_SETTING_VIEW,
                      ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_SETTING_VIEW,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <ActionDropdown
                      onDelete={() => {
                        customFieldOps(i, 'delete');
                      }}
                      onDeleteOptions={(deletedOptions: any) => {
                        let tempDelRef = deleteOptionsRef.current ?? [];
                        const { color, name } = deletedOptions[0];
                        let deletedField = {
                          value: name,
                          mapping_id: i?.mapping_id,
                        };

                        tempDelRef.push(deletedField);
                        deleteOptionsRef.current = tempDelRef;
                      }}
                      onChange={(changedValues, allValues) => {
                        if (!changedValues?.width) return;

                        const newWidth =
                          changedValues?.width ?? allValues?.width;
                        let newTaskDetails: any = {};

                        setTaskListDetails((prev: any) => {
                          const newCustomMeta = prev.custom_meta.map(
                            (j: any) => {
                              if (j?.mapping_id === i?.mapping_id) {
                                j.tempWidth = newWidth;
                              }
                              return j;
                            },
                          );

                          newTaskDetails = {
                            ...prev,
                            custom_meta: newCustomMeta,
                          };

                          return newTaskDetails;
                        });
                      }}
                      onSave={(allValues) => {
                        const newWidth = allValues?.width;
                        const newName = allValues?.columnName;
                        const description = allValues?.description;
                        let newTaskDetails: any = {};
                        setTaskListDetails((prev: any) => {
                          const col_rank = prev?.settings?.col_rank ?? [];
                          const newCustomMeta = prev.custom_meta
                            .map((j: any) => {
                              if (j?.mapping_id !== i?.mapping_id) return j;

                              j.width = newWidth;
                              j.name = newName ? newName : j.name;
                              j.description = description;

                              if (j.id === 2) {
                                j.number_format =
                                  allValues?.number_format ?? j.number_format;
                                j.precision =
                                  allValues?.precision ?? j?.precision;
                                j.direction =
                                  allValues?.direction ?? j?.direction;
                                j.alignment =
                                  allValues?.alignment ?? j?.alignment;
                                j.selectedFormat =
                                  allValues?.selectedFormat ??
                                  j?.selectedFormat;
                                j.selectedNegativeNumberFormat =
                                  allValues?.selectedNegativeNumberFormat ??
                                  j?.selectedNegativeNumberFormat;
                              }
                              if (j.id === 7) {
                                j.selectedDateFormat =
                                  allValues.selectedDateFormat ??
                                  j.selectedDateFormat;
                                j.showTime = allValues?.showTime ?? j?.showTime;
                              }

                              if (j.tempWidth) delete j.tempWidth;

                              if (
                                (j.id === 1 || j.id === 4 || j.id === 5) &&
                                allValues?.options?.length
                              ) {
                                j.options = allValues.options?.map(
                                  (option: any) => {
                                    return {
                                      value: option?.name ?? option?.value,
                                      color: option?.color,
                                    };
                                  },
                                );
                              }

                              customFieldOps(j, 'update');

                              return j;
                            })
                            .sort((a: any, b: any) => {
                              const indexA = col_rank.indexOf(a.key);
                              const indexB = col_rank.indexOf(b.key);
                              return indexA - indexB;
                            });

                          newTaskDetails = {
                            ...prev,
                            custom_meta: newCustomMeta,
                          };

                          if (deleteOptionsRef.current?.length) {
                            newTaskDetails?.task_list_details?.map(
                              (task: any) => {
                                task.custom_data = task?.custom_data?.filter(
                                  (cd: any) =>
                                    !deleteOptionsRef.current?.some(
                                      (doItem: any) =>
                                        cd.mapping_id === doItem.mapping_id &&
                                        cd.value === doItem.value,
                                    ),
                                );
                                return task;
                              },
                            );
                          }
                          return newTaskDetails;
                        });

                        handleUpdateSettings(newTaskDetails, 'width');

                        const newSettings = newTaskDetails?.settings;
                        const col_rank = newSettings?.col_rank ?? [];
                        const newCols = [...getColumns(newTaskDetails)].sort(
                          (a, b) => {
                            const indexA = col_rank.indexOf(a.key);
                            const indexB = col_rank.indexOf(b.key);
                            return indexA - indexB;
                          },
                        );
                        composeColumns(newCols);

                        const data = getDataSource(
                          newTaskDetails?.task_list_details,
                        );
                        setDataSource(data);
                      }}
                      onSaveSetting={() => {}}
                      defaultValue={{
                        width: i.width ? i.width : newCustomFieldColumnWidth,
                        columnName: i.name,
                      }}
                      editForm={{
                        ...i,
                        addToQuickFilter: i?.addToQuickFilter || false,
                      }}
                      onClose={() => (deleteOptionsRef.current = null)}
                    >
                      <div onClick={(e) => e.stopPropagation()}>
                        <MoreOutlined />
                      </div>
                    </ActionDropdown>
                  </Rbac>
                ) : null}
              </div>
            ),
            width: i?.tempWidth
              ? i?.tempWidth
              : i.width || newCustomFieldColumnWidth,
            hidden: i?.hidden ?? false,
            dataIndex: i.mapping_id,
            key: i.mapping_id,
            fixed: false,
            columnIndex: index,
            showSorterTooltip: { target: 'sorter-icon', color: '#2E364C' },
          };

          if (i.id === 1 || i.id === 4) {
            column.render = (text: any, record: any) => {
              const options = i.options
                .map((i: any) => ({
                  ...i,
                  label: i.value,
                  value: i.value,
                }))
                .filter((i: any) => i.value?.trim());
              const currentValue = options.find((i: any) => i.value === text);
              return (
                <PriorityDropdown
                  options={options}
                  value={currentValue?.value}
                  disabled={projectDetails?.is_archived}
                  onChange={(value) => {
                    const newTaskDetails = updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    const newSettings = newTaskDetails?.settings;
                    const col_rank = newSettings?.col_rank ?? [];
                    const newCols = [...getColumns(newTaskDetails)].sort(
                      (a, b) => {
                        const indexA = col_rank.indexOf(a.key);
                        const indexB = col_rank.indexOf(b.key);
                        return indexA - indexB;
                      },
                    );
                    composeColumns(newCols);

                    const data = getDataSource(
                      newTaskDetails?.task_list_details,
                    );
                    setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskList(newTaskDetails);
                    }
                  }}
                  onSelect={(value) => {
                    if (value === currentValue?.value) {
                      handleToggleEdit(i.id, record.task_id, i.mapping_id);
                    }
                  }}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? '').localeCompare(
                  b?.[i?.mapping_id] ?? '',
                );
              };
            }
          }

          if (i.id === 2) {
            column.render = (text: any, record: any) => {
              return (
                <CustomNumberField
                  taskDetail={record}
                  disabled={projectDetails?.is_archived}
                  value={text as number}
                  configuration={i}
                  sectionId={sectionId}
                  setTaskListDetails={setTaskListDetails}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                return (a?.[i?.mapping_id] ?? 0) - (b?.[i?.mapping_id] ?? 0);
              };
            }
          }

          if (i.id === 3) {
            column.render = (text: any, record: any) => {
              return <UploadField disabled={projectDetails?.is_archived} />;
            };
          }

          if (i.id === 5) {
            column.render = (text: any, record: any) => {
              const options = i.options
                .map((i: any, index: number) => ({
                  ...i,
                  label: i.value,
                  value: i.value !== '' ? `${i.value}-${index}` : i.value,
                }))
                .filter((i: any) => i.value?.trim());
              const currentValue = options.find((i: any) => i.value === text);
              const allValues = options.filter((i: any) =>
                text?.includes(i.value),
              );
              return (
                <MultiSelectDropdown
                  options={options}
                  values={allValues}
                  onChange={(value) => {
                    const newTaskDetails = updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    const newSettings = newTaskDetails?.settings;
                    const col_rank = newSettings?.col_rank ?? [];
                    const newCols = [...getColumns(newTaskDetails)].sort(
                      (a, b) => {
                        const indexA = col_rank.indexOf(a.key);
                        const indexB = col_rank.indexOf(b.key);
                        return indexA - indexB;
                      },
                    );
                    composeColumns(newCols);

                    const data = getDataSource(
                      newTaskDetails?.task_list_details,
                    );
                    setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskList(newTaskDetails);
                    }
                  }}
                  onSelect={(value) => {
                    if (value === currentValue?.value) {
                      handleToggleEdit(i.id, record.task_id, i.mapping_id);
                    }
                  }}
                  disabled={projectDetails?.is_archived}
                />
              );
            };

            if (i.sortable) {
              column.sorter = (a: any, b: any) => {
                const aValue = Array.isArray(a?.[i?.mapping_id])
                  ? a[i?.mapping_id].join(',')
                  : a?.[i?.mapping_id] ?? '';

                const bValue = Array.isArray(b?.[i?.mapping_id])
                  ? b[i?.mapping_id].join(',')
                  : b?.[i?.mapping_id] ?? '';

                return aValue.localeCompare(bValue);
              };
            }
          }
          if (i.id === 6) {
            column.render = (text: any, record: any) => {
              return (
                <CustomTextField
                  taskDetail={record}
                  disabled={projectDetails?.is_archived}
                  value={text}
                  configuration={i}
                  sectionId={sectionId}
                  setTaskListDetails={setTaskListDetails}
                />
              );
            };
          }
          if (i.id === 7) {
            column.render = (text: any, record: any) => {
              return (
                <DateTimeField
                  onSave={(value) => {
                    const newTaskDetails = updateCFData(
                      value,
                      i.id,
                      record.task_id,
                      i.mapping_id,
                      taskDetail!,
                    );

                    const newSettings = newTaskDetails?.settings;
                    const col_rank = newSettings?.col_rank ?? [];
                    const newCols = [...getColumns(newTaskDetails)].sort(
                      (a, b) => {
                        const indexA = col_rank.indexOf(a.key);
                        const indexB = col_rank.indexOf(b.key);
                        return indexA - indexB;
                      },
                    );
                    composeColumns(newCols);

                    const data = getDataSource(
                      newTaskDetails?.task_list_details,
                    );
                    setDataSource(data);
                    if (typeof record.task_id === 'number') {
                      handleUpdateTaskList(newTaskDetails);
                    }
                  }}
                  dateTimeValue={text}
                  config={i}
                />
              );
            };
          }
          return column;
        }
      })
      .filter((i) => i?.key);

    return metaData;
  };

  const sumColumnWidth = (columns: TableColumnsType<DataType>) => {
    let tableWidth = columns.reduce(
      (acc, current) =>
        acc + Number(current?.hidden ? 0 : current?.width || 175),
      0,
    );
    tableWidth = projectDetails?.is_archived ? tableWidth + 60 : tableWidth;
    return tableWidth;
  };

  async function customFieldOps(
    option: any,
    type: 'add' | 'update' | 'delete' = 'add',
  ) {
    try {
      const mappingId = option?.mapping_id;

      if (type === 'delete' && mappingId) {
        const updatedTaskList = await deleteCustomFieldAPI(mappingId);
        if (updatedTaskList?.response?.data?.messageId === -4) {
          const error = new Error(updatedTaskList?.response?.data?.message);
          (error as any).messageId = updatedTaskList?.response?.data?.messageId;
          throw error;
        }
        const emptyTask = {
          task_id: cryptoRandomString({ length: 5 }),
          task_rank: 1,
          task_name: '',
          due_date: '',
          assignee_dp: '',
          assignee_id: null,
          assignee_name: '',
          assignee_email: '',
          assigner_id: null,
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) => eachOption.status_id === 1,
          )?.status_name,
          task_priority_name: taskListCmsData?.task_priority_options.find(
            (eachOption) => eachOption.priority_id === 2,
          )?.priority_name,
          created_by: userDetails?.user_id,
          custom_data: null,
        };
        const task =
          Object.keys(updatedTaskList).length > 0
            ? updatedTaskList
            : [emptyTask];
        const newDetails = deleteCustomField(mappingId, task);
        const newTaskDetails = {
          ...mappingDetails(newDetails),
        };
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const newDataSource = getDataSource(newTaskDetails?.task_list_details);
        setDataSource(newDataSource);
      }
      const { data, messageId } = await updateCustomField({
        customMeta: type === 'add' ? option.default_meta : option,
        userId: Number(taskDetails?.created_by),
        sectionId: Number(taskDetails?.section_id),
        sectionTypeId: Number(taskDetails?.section_type_id),
        deleteOptions: deleteOptionsRef.current,
      });

      if (messageId === -4) {
        const error = new Error(
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        (error as any).messageId = messageId;
        throw error;
      }

      if (type === 'add') {
        const newTaskDetails = addCustomField(option, JSON.parse(data));
        await handleUpdateSettings(newTaskDetails as any, 'orderRow');
      }

      if (messageId !== 1 && type === 'add') revertCustomField(option);
    } catch (error: any) {
      if (error?.messageId === -4) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      revertCustomField(option);
    }
  }

  const handleUpdateTaskList = async (taskDetails: ITaskList) => {
    if (taskDetails) {
      const updatedTaskList = await updateExistingTask(
        (taskDetails.task_list_details as IUpdatedTasks[]) ?? [],
      );

      const newDetails = changeCustomField(updatedTaskList);

      const newTaskDetails = {
        ...mappingDetails(newDetails),
      };

      const newSettings = newTaskDetails?.settings;
      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });
      composeColumns(newCols);

      const newDataSource = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(newDataSource);
    }
  };

  const handleUpdateSettings = async (
    taskDetails?: ITaskList,
    type: 'orderColumn' | 'orderRow' | 'hiding' | 'width' = 'hiding',
    settings?: any,
  ) => {
    if (taskDetails) {
      await updateSettings(taskDetails);

      if (type === 'width') {
        const newTaskDetails = taskDetails;
        const newSettings = newTaskDetails?.settings;
        const col_rank = newSettings?.col_rank ?? [];
        const newCols = [...getColumns(taskDetails)].sort((a, b) => {
          const indexA = col_rank.indexOf(a.key);
          const indexB = col_rank.indexOf(b.key);
          return indexA - indexB;
        });
        composeColumns(newCols);

        const data = getDataSource(newTaskDetails?.task_list_details);
        setDataSource(data);
        await updateSettings(newTaskDetails);
        return;
      }

      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const newSettings = newTaskDetails?.settings;
      setTaskListDetails(newTaskDetails);
      await updateSettings(newTaskDetails);

      if (['orderColumn', 'hiding'].includes(type)) return;

      const col_rank = newSettings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(data);
    }
  };

  // Effects
  useEffect(() => {
    if (columns && taskDetails) {
      const newTaskDetails = {
        ...mappingDetails(taskDetails),
      };
      const settings = taskDetails?.settings;
      const col_rank = settings?.col_rank ?? [];
      const newCols = [...getColumns(newTaskDetails)].sort((a, b) => {
        const indexA = col_rank.indexOf(a.key);
        const indexB = col_rank.indexOf(b.key);
        return indexA - indexB;
      });

      composeColumns(newCols);

      const data = getDataSource(newTaskDetails?.task_list_details);
      setDataSource(data);
    }
  }, [taskDetails, projectDetails]);
  return (
    <div className="custom-table-container">
      {taskDetails &&
        createPortal(
          <>
            <HideColumns
              columns={
                columns.filter((i: any) => {
                  return (
                    [dueDate, assignee].includes(i.key) ||
                    checkIsCustomField(i.mapping_id)
                  );
                }) as any[]
              }
              onMenuClick={(newData: any[]) => {
                setColumns((prev: any) => {
                  // Handle map `columns` to `newData`
                  const newColumns = prev.map((i: any) => {
                    const found = newData.find((j) => j?.key === i?.key);
                    if (found) {
                      return {
                        ...i,
                        hidden: found?.hidden,
                      };
                    }
                    return i;
                  });

                  if (taskDetails) {
                    const newTaskDetails = {
                      ...taskDetails,
                      custom_meta: convertColumnsToCustomMeta(
                        newColumns,
                      ).filter((i: any) => i),
                    };

                    if (newTaskDetails) {
                      handleUpdateSettings(newTaskDetails, 'hiding');
                    }
                  }
                  return newColumns;
                });
              }}
            />
          </>,
          document.getElementById(
            `table-section-filter-container-${taskDetails?.section_id}`,
          )!,
        )}
      <div className="antTableWrap">
        <div className="simpleTblWrap">
          <ConfigProvider
            theme={{
              token: {
                colorText: '#2e364c',
              },
            }}
            locale={localeMap?.[language] ?? enUS}
          >
            <TableView
              disabled={projectDetails?.is_archived}
              className="nested-table"
              columns={!columns?.length ? DEFAULT_COLUMNS : columns}
              onColumnOrderChange={(_: any, fromKey: any, toKey: any) => {
                setColumns((prev) => {
                  const newColumns = [...prev];
                  const fromIndex = newColumns.findIndex(
                    (i) => i?.key === fromKey,
                  );
                  const toIndex = newColumns.findIndex((i) => i?.key === toKey);
                  const fromColumn = newColumns[fromIndex];
                  const toColumn = newColumns[toIndex];

                  if (fromColumn?.fixed || toColumn?.fixed) {
                    message.warning(
                      taskListCmsData?.lbl_warning_invalid_reorder_operation,
                    );
                    return newColumns;
                  }

                  if (fromIndex >= 0 && toIndex >= 0) {
                    newColumns.splice(
                      toIndex,
                      0,
                      newColumns.splice(fromIndex, 1)[0],
                    );
                  }

                  if (taskDetails) {
                    const newTaskDetails = {
                      ...taskDetails,
                      custom_meta: convertColumnsToCustomMeta(
                        newColumns,
                      ).filter((i: any) => i),
                    };

                    if (newTaskDetails) {
                      handleUpdateSettings(newTaskDetails, 'orderColumn');
                    }
                  }

                  return newColumns;
                });
              }}
              onRowReOrder={(newDataSource) => {
                if (taskDetails) {
                  setDataSource(getDataSource(newDataSource));
                  handleUpdateSettings(
                    {
                      ...taskDetails,
                      custom_meta: convertColumnsToCustomMeta(columns).filter(
                        (i: any) => i,
                      ),
                      task_list_details: newDataSource,
                    },
                    'orderRow',
                  );
                }
              }}
              onClickAdd={() => {}}
              dataSource={
                taskDetails?.hide_completed_tasks
                  ? dataSource?.filter(
                      (item) =>
                        item.task_status_id !== 3 ||
                        typeof item.task_id === 'string',
                    )
                  : dataSource
              }
              size="small"
              bordered
              pagination={false}
              key="task_id"
              rowKey="task_id"
              scroll={{
                x: sumColumnWidth(columns),
              }}
              // loading={!dataSource?.length}
            />
            {!isTaskEmpty && !projectDetails?.is_archived && (
              <Rbac
                allowedPermissions={[
                  ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_FIELD_ADD,
                  ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_ADD,
                ]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <AddCustomField
                  customfieldCmsData={customfieldCmsData}
                  options={customFields ?? []}
                  onClick={(option) => customFieldOps(option, 'add')}
                />
              </Rbac>
            )}
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default CTable;
