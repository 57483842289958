import { Input, Popover, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CCalendar from 'components/sharedComponents/CDatePicker/CCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarOutlined } from '@ant-design/icons';
import { eventEmitter } from '../../../../../../../../services/EventEmitter/eventemitter.service';
import { useProjectDashboardStore } from 'stores';
import { IProjectState } from 'models/interface';
import { detectDateFormat } from 'utils/commonFunctions';

type DateTimeValue = {
  Date: string;
  Time: string;
};

interface IDateTimeFieldProps {
  onSave: (value: any) => void;
  dateTimeValue: DateTimeValue;
  config: any;
}

const DateTimeField: React.FC<IDateTimeFieldProps> = ({
  onSave,
  dateTimeValue,
  config,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [formattedAndStoredDate, setFormattedAndStoredDate] =
    useState<string>('');
  const [openCalender, setOpenCalender] = useState<boolean>(false);
  const [selectedTimeString, setSelectedTimeString] = useState<string>('');
  const [selectedDateString, setSelectedDateString] = useState<string>('');

  let newFormat = config.date_formats.find(
    (itm: any) => itm.id === config?.selectedDateFormat,
  )?.value;

  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const getRelativeDayString = (
    date: Dayjs,
    format: string,
    includeTime: boolean,
  ): string => {
    const today = dayjs();
    const tomorrow = today.add(1, 'day');
    const yesterday = today.subtract(1, 'day');

    switch (true) {
      case date.isSame(today, 'day'):
        return `${
          customfieldCmsData?.lbl_column_date_time_settings
            ?.lbl_date_settings_today_text
        }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
      case date.isSame(tomorrow, 'day'):
        return `${
          customfieldCmsData?.lbl_column_date_time_settings
            ?.lbl_date_settings_tomorrow_text
        }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
      case date.isSame(yesterday, 'day'):
        return `${
          customfieldCmsData?.lbl_column_date_time_settings
            ?.lbl_date_settings_yesterday_text
        }${includeTime ? `, ${date.format('hh:mm A')}` : ''}`;
      default:
        return date.format(format);
    }
  };

  const formatDateAndStore = (dateTime: Dayjs | null) => {
    let formattedDate = ' ';
    let includeTime =
      config.showTime === true && dateTimeValue && dateTimeValue?.Time !== null;
    let finalFormat = `${newFormat}${includeTime ? ', hh:mm A' : ''}`;
    if (dateTime !== null) {
      formattedDate = getRelativeDayString(dateTime, finalFormat, includeTime);
    }
    setFormattedAndStoredDate(formattedDate);
  };

  const convertDateTimeObjectIntoDayJsObject = (dateTime: DateTimeValue) => {
    let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
    let timeFormat = 'hh:mm A';
    let formattedValue: Dayjs | null;
    let formattedTimeString: string;
    let formattedDateString: string;

    if (dateTime.Date === null && dateTime.Time === null) {
      formattedValue = null;
      formattedTimeString = '';
      formattedDateString = '';
    } else {
      if (dateTime.Time !== null) {
        formattedValue = dayjs(`${dateTime.Date} ${dateTime.Time}`, [
          `YYYY-MM-DD ${timeFormat}`,
          `DD/MM/YYYY ${timeFormat}`,
          `MM/DD/YYYY ${timeFormat}`,
        ]);
        formattedDateString = dayjs(`${dateTime.Date}`, [
          'YYYY-MM-DD',
          'DD/MM/YYYY',
          'MM/DD/YYYY',
        ]).format(`${dateFormat}`);
        formattedTimeString = dateTime.Time;
      } else {
        formattedValue = dayjs(`${dateTime.Date}`, [
          'YYYY-MM-DD',
          'DD/MM/YYYY',
          'MM/DD/YYYY',
        ]);
        formattedDateString = dayjs(`${dateTime.Date}`, [
          'YYYY-MM-DD',
          'DD/MM/YYYY',
          'MM/DD/YYYY',
        ]).format(`${dateFormat}`);
        formattedTimeString = '';
      }
    }
    formatDateAndStore(formattedValue);
    setSelectedDate(formattedValue);
    setSelectedDateString(formattedDateString);
    setSelectedTimeString(formattedTimeString);
  };

  const setCalenderSupportedFormat = () => {
    if (dateTimeValue) {
      convertDateTimeObjectIntoDayJsObject(dateTimeValue);
    } else {
      setSelectedDate(null);
      setSelectedTimeString('');
      setFormattedAndStoredDate('');
    }
  };

  const resetComponentStates = () => {
    setOpenCalender(false);
  };

  const clearDates = () => {
    onSave({
      Date: null,
      Time: null,
    });
    setSelectedDate(null);
    setSelectedDateString('');
    setSelectedTimeString('');
  };
  const updateDateTimeStringValue = () => {
    if (selectedDate !== null) {
      let dateFormat = detectDateFormat() ?? 'DD/MM/YYYY';
      let formattedDateString: string = dayjs(selectedDate).format(dateFormat);
      setSelectedDateString(formattedDateString);
    }
  };

  const getContentJSX = () => {
    if (
      dateTimeValue === undefined ||
      (dateTimeValue.Date === null && dateTimeValue.Time === null)
    ) {
      return (
        <span className="dateTimeFieldPlaceholder">{`${newFormat}, ${
          config.showTime ? 'hh:mm A' : ''
        }`}</span>
      );
    }

    return (
      <span className="dateTimeFetchedData">{formattedAndStoredDate}</span>
    );
  };

  useEffect(() => {
    setCalenderSupportedFormat();
  }, [dateTimeValue, newFormat]);

  useEffect(() => {
    updateDateTimeStringValue();
  }, [selectedDate]);

  useEffect(() => {
    if (Boolean(openCalender) === false) {
      eventEmitter.emit('resetDateTimeInput');
    } else {
      setCalenderSupportedFormat();
    }
  }, [openCalender]);

  return (
    <Popover
      placement="bottom"
      content={
        <CCalendar
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          onCancel={() => setOpenCalender(false)}
          onOk={() => {
            onSave({
              Date:
                selectedDateString === ''
                  ? null
                  : dayjs(selectedDate).format('DD/MM/YYYY'),
              Time: selectedTimeString === '' ? null : selectedTimeString,
            });
            setOpenCalender(false);
          }}
          onClear={() => {
            clearDates();
          }}
          open={true}
          isUsedInCustomFields={true}
          timeString={selectedTimeString}
          dateString={selectedDateString}
          setSelectedDateString={setSelectedDateString}
          setSelectedTimeString={setSelectedTimeString}
          setSelectedDate={setSelectedDate}
        />
      }
      trigger="click"
      overlayClassName="gridAssigneeListPopOver"
      open={openCalender}
      onOpenChange={(event) => {
        if (event === false) {
          resetComponentStates();
        }
      }}
    >
      <div
        onClick={() => setOpenCalender(true)}
        className="addEditDateTimeFieldContent"
      >
        <Tooltip title={getContentJSX()}>{getContentJSX()}</Tooltip>
      </div>
    </Popover>
  );
};

export default DateTimeField;
