import { Form, Input, Radio, Select, Space } from 'antd';
import { useCallback, useMemo } from 'react';

interface NegativeConfig {
  colour: string;
  signed: boolean;
  brackets: boolean;
  id: number;
  description: string;
}

const getNegativeLabel = (config: NegativeConfig) => {
  if (!config?.description) return 'None';
  let content = '123.45';
  if (config.signed) content = `-${content}`;
  if (config.brackets) content = `(${content})`;
  return content;
};
const NumberForm = (props: any) => {
  const {
    setFieldValue,
    negative_number_format,
    number_format,
    form,
    setChangeFields,
    editForm,
  } = props;
  const decimalOptions = new Array(6).fill(null).map((_, index) => ({
    label: index,
    value: index,
  }));

  const format = Form.useWatch('selectedFormat', form);
  const selectedNegativeNumberFormat = Form.useWatch(
    'selectedNegativeNumberFormat',
    form,
  );
  const direction = Form.useWatch('direction', form);
  const precision = Form.useWatch('precision', form);
  const customFormat = Form.useWatch('customFormat', form);

  const negativeConfig = useMemo(() => {
    return (
      negative_number_format?.find(
        (ele: NegativeConfig) => ele.id === selectedNegativeNumberFormat,
      ) || null
    );
  }, [selectedNegativeNumberFormat]);
  const numberFormat = useMemo(() => {
    return (
      number_format?.find((ele: NegativeConfig) => ele?.id === format) || null
    );
  }, [format]);

  const createPreview = () => {
    let content = '100';
    const decimalPart = '12345';
    const unit =
      numberFormat && numberFormat?.id !== -1
        ? numberFormat?.description ?? ''
        : customFormat ?? '';

    const prefix = direction === 1 ? `${unit} ` : '';
    const suffix = direction === 2 ? ` ${unit}` : '';
    content += precision > 0 ? `.${decimalPart.slice(0, precision)}` : '';
    if (negativeConfig?.signed) content = `-${content}`;
    if (negativeConfig?.brackets) content = `(${content})`;
    content = `${prefix}${content}${suffix}`;
    return content;
  };

  const getNegativeLabelColor = useCallback(
    (value: number) => {
      const findOption: NegativeConfig = props?.negative_number_format?.find(
        (ele: NegativeConfig) => ele.id === value,
      );
      return findOption?.colour;
    },
    [props?.negative_number_format],
  );

  return (
    <div>
      <div className="unitSubContent">
        <label>Format</label>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item name={'selectedFormat'} className="noGap">
            <Radio.Group
              defaultValue={props?.selectedFormat || 1}
              size="small"
              // className="numberFormElement"
              style={{ margin: 0, height: 'unset', display: 'flex' }}
            >
              {props?.number_format?.map((format: any) =>
                format.id !== -1 ? (
                  <Radio.Button
                    key={format.id}
                    style={{
                      height: 32,
                      margin: 0,
                      whiteSpace: 'nowrap',
                      display: 'flex',
                      alignItems: 'center',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    value={format?.id}
                  >
                    {format?.description || 'None'}
                  </Radio.Button>
                ) : null,
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="customFormat" className="noGap">
            <Input
              placeholder="Type your own"
              autoComplete={'off'}
              onFocus={() => {
                setFieldValue?.('selectedFormat', -1);
                setChangeFields((prev: Record<string, boolean>) => ({
                  ...prev,
                  selectedFormat: editForm?.selectedFormat !== -1,
                }));
              }}
              onChange={(event) => {
                setFieldValue?.('selectedFormat', -1);
                setFieldValue?.(
                  'customFormat',
                  event?.target?.value?.trim().slice(0, 4),
                );
              }}
              maxLength={4}
              style={{
                display: 'inline-block',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                marginLeft: -1,
                borderColor: format === -1 ? '#4b8Dff' : undefined,
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <div className="unitSubContent" style={{ flex: 4 }}>
          <label>Direction</label>
          <Form.Item name={'direction'} style={{ margin: 0 }}>
            <Radio.Group
              defaultValue={props?.direction || 1}
              style={{ margin: 0 }}
              size="small"
              // className="numberFormElement"
            >
              {props?.directions?.map((d: any) => (
                <Radio.Button key={d?.id} value={d?.id}>
                  {d?.description || 'None'}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="unitSubContent" style={{ flex: 5 }}>
          <label>Alignment</label>
          <Form.Item name={'alignment'} style={{ margin: 0 }}>
            <Radio.Group
              defaultValue={props?.alignment || 3}
              style={{ margin: 0, width: '100%' }}
              size="small"
              // className="numberFormElement"
            >
              {props?.alignments?.map((d: any) => (
                <Radio.Button value={d?.id}>
                  {d?.description || 'None'}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <div className="unitSubContent" style={{ flex: 4 }}>
          <label>Precision</label>
          <Form.Item name={'precision'} style={{ margin: 0 }}>
            <Select
              style={{
                width: '100%',
              }}
              options={decimalOptions}
            />
          </Form.Item>
        </div>
        <div className="unitSubContent" style={{ flex: 5 }}>
          <label>Negative Number Format</label>
          <Form.Item
            name={'selectedNegativeNumberFormat'}
            style={{ margin: 0 }}
          >
            <Select
              style={{
                width: '100%',
              }}
              options={negative_number_format?.map((ele: any) => ({
                ...ele,
                label: getNegativeLabel(ele),
                value: ele.id,
              }))}
              labelRender={(props) => (
                <span
                  style={{
                    color: getNegativeLabelColor(props.value as number),
                  }}
                >
                  {props.label}
                </span>
              )}
              optionRender={optionRender}
            />
          </Form.Item>
        </div>
      </div>
      <div className="unitSubContent">
        <label>Preview</label>
        <span style={{ color: negativeConfig?.colour }}>{createPreview()}</span>
      </div>
    </div>
  );
};
export default NumberForm;

const optionRender = (option: any) => {
  return (
    <Space>
      <span
        style={{
          color: option.data?.colour || '#2E364C',
        }}
        aria-label={option.data.label}
      >
        {option.data.label}
      </span>
    </Space>
  );
}