import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { message, notification } from 'antd';
import {
  ICustomFieldModalAttributes,
  IGridList,
  IGridListStageDetail,
  IGridListTaskDetail,
  IProjectSections,
  IProjectState,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import TaskListWrapper from './Task/TaskListWrapper';
import StageListWrapper from './Stage/StageListWrapper';
import { loggerService, projectService } from 'services';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import AddStageCTA from './Stage/AddStageCTA';
import AddTaskCTA from './Task/AddTaskCTA';
import { GRID_VIEW_TYPES } from '../GridList';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import AddCustomField from '../AddCustomField';
import useGridService from '../services/grid.service';
import useGridHandler, { checkIsCustomField } from '../services/grid.handler';
import GLV4StageNameInputField from '../Stage/StageNameInputField';
import classNames from 'classnames';
import { arrayMove } from '@dnd-kit/sortable';

const CompactView: FC<{
  sectionDetails: IProjectSections;
  sectionToggleState: 'collapsed' | 'expanded';
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  customfieldCmsData?: ICustomFieldModalAttributes;
  getSectionDetails: () => void;
}> = (props) => {
  const {
    sectionDetails,
    sectionToggleState,
    gridListDetails,
    setGridListDetails,
    getSectionDetails
  } = props;
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  let deleteOptionsRef = useRef<any>(null);
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const { gridListCmsData, projectDetails, customFields, customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const {
    addEditCustomField,
    updateGridCustomFieldData,
    reOrderTaskStageAPI,
    updateSettings,
    deleteCustomFieldColumnAPI
  } = useGridService({
    org_key,
    userDetails,
    projectDetails,
    sectionDetails,
    gridListDetails,
    setGridListDetails,
  });
  const {
    // updateStageDragEnd,
    // updateTaskDragEnd,
    validateReOrderItems,
    updateCFCell,
    getCFBlockDataByTask,
  } = useGridHandler({
    gridListDetails,
    setGridListDetails,
  });

  const [customMetaMap, setCustomMetaMap] = useState<Map<string, any>>();
  useEffect(() => {
    const customFieldMap = new Map<string, any>();
    if (gridListDetails.custom_meta?.length) {
      gridListDetails.custom_meta.forEach((field) => {
        customFieldMap.set(field?.mapping_id, field);
      });
    }
    setCustomMetaMap(customFieldMap);
  }, [gridListDetails.custom_meta]);

  // Functions
  const reOrderTask = async (
    sourceTaskId: number,
    destinationTaskId: number,
  ) => {
    try {
      let response = await projectService.reOrderGridTask({
        organizationId: +getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
        projectId: projectDetails?.project_id!,
        sectionId: +sectionDetails.section_id,
        sourceTaskId,
        destinationTaskId,
      });

      if (response.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: response.data.grid_list_details.task_details,
          block_details: response.data.grid_list_details.block_details,
        },
      } as IGridList);
    } catch (err: any) {
      if (err?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'task delete failed',
        payload: 'DB Error',
      });
    }
  };

  const reOrderStage = async (
    sourceStageId: number,
    destinationStageId: number,
  ) => {
    try {
      let response = await projectService.reOrderGridStage({
        organizationId: +getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
        projectId: projectDetails?.project_id!,
        sectionId: +sectionDetails.section_id,
        sourceStageId,
        destinationStageId,
      });

      if (response.messageId === -4) {
        const error = new Error(response?.message);
        (error as any).messageId = response?.messageId;
        throw error;
      }

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: response.data.grid_list_details.stage_details,
          block_details: response.data.grid_list_details.block_details,
        },
      } as IGridList);
    } catch (err: any) {
      if (err?.messageId === -4) {
        fetchNewPermission(
          org_key,
          gridListCmsData?.lbl_error_message_permission_denied,
        );
        return;
      }
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: 'High',
        message: 'task delete failed',
        payload: 'DB Error',
      });
    }
  };

  const onDragEndTask = (result: DropResult) => {
    if (gridListDetails) {
      let source = result.source;
      let destination = result.destination!;

      if (!source || !destination) return;

      if (source.index !== destination.index) {
        let sourceTask =
          gridListDetails.grid_list_details!.task_details![source.index];

        let destinationTask =
          gridListDetails.grid_list_details!.task_details![destination.index];

        let otherTaskList =
          gridListDetails.grid_list_details!.task_details?.filter(
            (ele: IGridListTaskDetail) => ele.task_id !== sourceTask.task_id,
          );

        otherTaskList?.splice(destination.index, 0, sourceTask);

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: otherTaskList!,
          },
        } as IGridList);

        (async () => {
          await reOrderTask(sourceTask.task_id, destinationTask.task_id);
        })();
      }
    }
  };
  const onDragEndStage = (result: DropResult) => {

    if (gridListDetails) {
      let source = result.source;
      let destination = result.destination!;

      if (!source || !destination) {
        return;
      }

      const { isInvalidReOrder } = validateReOrderItems(
        source.index + 1,
        destination.index + 1,
      );
      if (isInvalidReOrder) {
        return message.warning('Invalid re-order operation!');
      }


      if (source.index !== destination.index) {
        const reOrderList = arrayMove(
          gridListDetails?.grid_list_details?.stage_details,
          source.index,
          destination.index);
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: reOrderList!,
          },
        } as IGridList);

        (async () => {
          const response = await reOrderTaskStageAPI(
            reOrderList?.map((stg: any) => {
              const width = customMetaMap?.get(stg?.stage_id)?.width || undefined
              return { ...stg, dataIndex: stg?.stage_id, width }
            }),
            gridListDetails?.grid_list_details?.task_details as any[]
          );
          setGridListDetails({
            ...gridListDetails,
            grid_list_details: {
              ...gridListDetails.grid_list_details,
              stage_details: response.data.grid_list_details.stage_details,
              block_details: response.data.grid_list_details.block_details,
            },
          } as IGridList);
        })();
      }
    }
  };

  const getViewClass = () => {
    const view_id = gridListDetails.view_id;
    if (gridListDetails?.compactView) return '';
    return GRID_VIEW_TYPES.find((i) => i.id === view_id)?.className ?? '';
  };

  if (sectionToggleState !== 'expanded') return <></>;

  const taskListDetails =
    gridListDetails.grid_list_details?.task_details || [];
  

  const handleAddCustomField = async (customMeta: any, adding: boolean = false) => {
    if (customMeta?.tempWidth) delete customMeta.tempWidth;
    const custom_meta: any = [];
    gridListDetails?.grid_list_details?.stage_details?.forEach((stage: IGridListStageDetail) => {
      if (stage?.stage_id && String(stage?.stage_id)?.includes('_')) {
        if (stage?.stage_id === customMeta?.mapping_id) {
          custom_meta.push(customMeta);
        } else {
          const findInCustomMeta = customMetaMap?.get(String(stage?.stage_id))
          custom_meta.push(findInCustomMeta)
        }
      }
    })
    const response = await addEditCustomField({
      customMeta,
      deleteOptions: deleteOptionsRef.current,
    });
    if (!adding) {
      await updateSettings({ ...gridListDetails, custom_meta });
    }
    await getSectionDetails();
    if (response?.messageId === -4) {
      fetchNewPermission(
        org_key,
        gridListCmsData?.lbl_error_message_permission_denied,
      );
    }
  };

  const onCustomFieldCellChange = async (value: string, cell: any) => {
    const blockId = cell?.block_id;
    const taskId = cell?.task_id;
    if (!blockId || !taskId) return;
    const taskName =
      gridListDetails?.grid_list_details?.task_details?.find(
        (i) => i.task_id === taskId,
      )?.task_name ?? '';

    const originData = { ...gridListDetails };

    try {
      updateCFCell(value, blockId, taskId);
      await updateGridCustomFieldData(
        taskName,
        taskId,
        getCFBlockDataByTask(taskId),
      );
    } catch (error) {
      console.error(error);
      message.error('Failed to update custom field');
      setGridListDetails(originData);
    }
  };

  const deleteCustomField = async (stage: any) => {
    try {
      await deleteCustomFieldColumnAPI(stage.stage_id);
      await getSectionDetails();
    } catch (error) {
      message.error('Failed to delete custom field');
    }
  };
  const customFieldUlWidth = gridListDetails?.custom_meta?.reduce((prev, curr) => {
    return prev + ((curr?.tempWidth ?? curr?.width) || 150)
  }, 0)


  return (
      <div
        className={
          'gridList2DOuterWrap ' +
          (gridListDetails?.compactView ? 'gridCompactView' : '')
        }
      >
        <div className={'gridList2DWrap ' + getViewClass()}>
          <table>
            <thead>
              <tr>
                <th>
                  <div className="gridHeading">
                    <span>{gridListCmsData?.lbl_task_header ?? 'Tasks'}</span>
                    {taskListDetails.length > 0 && !projectDetails?.is_archived && (
                      <Rbac
                        allowedPermissions={[
                          ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_FIELD_ADD,
                          ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_ADD,
                        ]}
                        project_role_id={projectDetails?.associated_role_id}
                      >
                        <AddCustomField
                          options={customFields ?? []}
                          onClick={(customFieldData: any) =>
                            handleAddCustomField(customFieldData.default_meta)
                          }
                          customfieldCmsData={customfieldCmsData}
                        />
                      </Rbac>
                    )}
                    <span className="verticleTxt">
                      {gridListCmsData?.lbl_stage_header ?? 'Stages'}
                    </span>
                  </div>
                </th>
                {gridListDetails.grid_list_details?.stage_details?.length && (
                  <th>
                    <div className='compactViewHeaderChild'>
                    <DragDropContext
                      onDragUpdate={(e) => {
                        const document = window.document.querySelector(
                          `#gridSection-${sectionDetails.section_id} .gridList2DWrap`,
                        );

                        if (document) {
                          const verticalScolling = Boolean(document.scrollTop);

                          if (verticalScolling) {
                            notification.open({
                              key: 1,
                              message:
                                "You're not able to DnD while scrolling!",
                              description:
                                'Please scroll (hold Shift for horizontal) to the top/left',
                              placement: 'bottomLeft',
                              type: 'warning',
                            });
                          }
                        }
                      }}
                      onDragEnd={onDragEndStage}
                    >
                      <Droppable
                        droppableId="droppableStage"
                        direction="horizontal"
                      >
                        {(provided: any) => {
                          return (
                            <ul
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ height: '50px', width: customFieldUlWidth ?? undefined }}
                            >
                              {gridListDetails?.grid_list_details?.stage_details?.map(
                                (ele: IGridListStageDetail, index: number) => {
                                  if (!checkIsCustomField(ele.stage_id)) return null;
                                  const customMeta = customMetaMap?.get(ele.stage_id.toString())
                                  return (
                                    <Draggable
                                      key={ele.stage_id}
                                      draggableId={`${ele.stage_id}`}
                                      index={index}
                                      isDragDisabled={
                                        gridListDetails.grid_list_details?.stage_details?.length ===
                                        1 || projectDetails?.is_archived
                                      }
                                    >
                                      {(provided: any) => {
                                        return (
                                          <li
                                            key={ele.stage_id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={
                                              classNames('customFieldStage', {
                                                notDraggable: projectDetails?.is_archived
                                              })
                                            }
                                          >
                                            <GLV4StageNameInputField
                                              stageDetails={ele}
                                              gridListDetails={gridListDetails}
                                              setGridListDetails={setGridListDetails}
                                              index={ele.stage_id}
                                              customMeta={{
                                                ...customMeta,
                                                width: customMeta?.width ?? 150,
                                              }}
                                              updateCustomMeta={handleAddCustomField}
                                              deleteOptionsRef={deleteOptionsRef}
                                              deleteCustomField={() => deleteCustomField(ele)}
                                              width={Number(customMeta?.tempWidth ?? (customMeta?.width || 150)) - 8}
                                            />
                                          </li>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                }
                              )}

                            </ul>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                    <DragDropContext
                      onDragUpdate={(e) => {
                        const document = window.document.querySelector(
                          `#gridSection-${sectionDetails.section_id} .gridList2DWrap`,
                        );

                        if (document) {
                          const verticalScolling = Boolean(document.scrollTop);

                          if (verticalScolling) {
                            notification.open({
                              key: 1,
                              message:
                                "You're not able to DnD while scrolling!",
                              description:
                                'Please scroll (hold Shift for horizontal) to the top/left',
                              placement: 'bottomLeft',
                              type: 'warning',
                            });
                          }
                        }
                      }}
                      onDragEnd={onDragEndStage}
                    >
                      <Droppable
                        droppableId="droppableStage"
                        direction="horizontal"
                      >
                        {(provided: any) => {
                          return (
                            <ul
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <StageListWrapper
                                gridListDetails={gridListDetails}
                                setGridListDetails={setGridListDetails}
                                customMetaMap={customMetaMap}
                              />
                            </ul>
                          );
                        }}
                      </Droppable>
                    </DragDropContext>
                    </div>
                  </th>
                )}
              </tr>
            </thead>

            <DragDropContext onDragEnd={onDragEndTask}>
              <Droppable droppableId="droppableTask" direction="vertical">
                {(provided: any) => {
                  return (
                    <tbody
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TaskListWrapper
                        gridListDetails={gridListDetails}
                        setGridListDetails={setGridListDetails}
                        onCustomFieldCellChange={onCustomFieldCellChange}
                      />
                    </tbody>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
        {projectDetails?.is_archived === false && (
          <>
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_GRIDLIST_STAGE_ADD,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <AddStageCTA
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
              />
            </Rbac>
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_ADD,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <AddTaskCTA
                gridListDetails={gridListDetails}
                setGridListDetails={setGridListDetails}
              />
            </Rbac>
          </>
        )}
      </div>
  );
};

export default CompactView;
