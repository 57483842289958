import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { useEditor, BubbleMenu } from '@tiptap/react';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import StarterKit from '@tiptap/starter-kit';
import TaskItem from '@tiptap/extension-task-item';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import React, { FC, useEffect, useState } from 'react';
import { Dropdown, Input, MenuProps, Popover, Tooltip, message } from 'antd';
import {
  IMemopadAttributes,
  IProjectState,
} from '../../../../../../models/interface';
import addColumnBefore from '../../../../../../assets/images/add-column-before.png';
import addColumnAfter from '../../../../../../assets/images/add-column-after.png';
import deleteColumn from '../../../../../../assets/images/delete-column-before.png';
import addRowBefore from '../../../../../../assets/images/add-row-before.png';
import addRowAfter from '../../../../../../assets/images/add-row-after.png';
import deleteRow from '../../../../../../assets/images/delete-row.png';
import deleteTable from '../../../../../../assets/images/delete-table.png';
import mergeCells from '../../../../../../assets/images/merge-cells.png';
import splitCell from '../../../../../../assets/images/split-cells.png';
import toggleHeaderColumn from '../../../../../../assets/images/toggle-header-column.png';
import toggleHeaderRow from '../../../../../../assets/images/toggle-header-row.png';
import toggleHeaderCell from '../../../../../../assets/images/toggle-header-cell.png';
import toggleTaskList from '../../../../../../assets/images/toggle-task-list.png';
import splitListItem from '../../../../../../assets/images/split-task-list.png';
import sinkListItem from '../../../../../../assets/images/sink-list.png';
import liftListItem from '../../../../../../assets/images/lift-list.png';
import {
  BoldOutlined,
  ItalicOutlined,
  LinkOutlined,
  OrderedListOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import FileUploader from './FileUploader';
import { Link } from '@tiptap/extension-link';
import MemopadLink from './MemopadLink';
import { RichTextEditor } from '@mantine/tiptap';
import { useProjectDashboardStore } from '../../../../../../stores';
import classNames from 'classnames';

export enum ContentType {
  TEXT,
  TABLE,
  LIST,
}

enum ContentControl {
  MOVE_UP = 'MOVE_UP',
  REMOVE = 'REMOVE',
  MOVE_DOWN = 'MOVE_DOWN',
}

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const extensions = [
  Document,
  Text,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Underline,
  TaskList.configure({
    HTMLAttributes: {
      class: 'memopadTasklist',
    },
  }),
  TaskItem.configure({
    nested: true,
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  TextAlign.configure({ types: ['heading', 'paragraph'] }),
  Superscript,
  SubScript,
];

const TiptapEditor: FC<{
  content: { [key: string]: any };
  index: number;
  memopadCmsData: IMemopadAttributes | null;
  onUpdate: (data: Object, index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onRemove: (index: number) => void;
  maxIndex: number;
  projectId: number;
  organizationId: number;
  disableEditor?: boolean;
  focusLink?: boolean;
  setFocusLink?: React.Dispatch<React.SetStateAction<boolean>>;
  focusOther?: boolean;
  setFocusOther?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  content,
  index,
  onUpdate,
  maxIndex,
  onMoveUp,
  onMoveDown,
  onRemove,
  organizationId,
  projectId,
  disableEditor,
  focusLink,
  setFocusLink,
  focusOther,
  setFocusOther,
}) => {
  const [isNew, setIsNew] = useState<boolean>(content.is_new ?? false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [showLinkPopover, setShowLinkPopover] = useState<boolean>(false);
  const [defaultLink, setDefaultLink] = useState<string>('');
  const [rangeSelection, setRangeSelection] = useState<{
    from: number;
    to: number;
  } | null>(null);
  const { projectDetails, messagingCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  extensions.push(
    Link.extend({
      inclusive: false,
      addAttributes() {
        return {
          ...this.parent?.(),
          href: {
            default: null,
            parseHTML: (element) => element.getAttribute('href'),
            renderHTML: (attributes) => {
              if (!attributes.href) {
                return {};
              }

              return {
                href: attributes.href,
                target: '_blank',
                rel: 'noopener noreferrer nofollow',
              };
            },
          },
        };
      },
      addCommands() {
        return {
          setLink:
            (options) =>
            ({ commands }) => {
              if (isValidUrl(options.href)) {
                return commands.setMark(this.name, options);
              } else {
                message.info(messagingCmsData.lbl_invalid_link, 2);
                return false;
              }
            },
          unsetLink:
            () =>
            ({ commands }) => {
              return commands.unsetMark(this.name);
            },
        };
      },
    }).configure({
      openOnClick: true,
      //validate: (href) => /^https?:\/\//.test(href),
      HTMLAttributes: {
        class: 'memopadLink',
        rel: 'noopener noreferrer',
        target: '_blank',
      },
    }),
  );

  const editor = useEditor({
    // @ts-ignore
    extensions: extensions,
    editable: !projectDetails?.is_archived && !disableEditor,
    content: content,
    //autofocus: isNew ? "start" : null,
    onFocus: () => {
      setIsEditMode(true);
    },
    onClick: () => {
      if (
        projectDetails?.is_archived === false &&
        Boolean(disableEditor) === false
      ) {
        setIsEditMode(true);
      }
    },
    onBlur: (evt) => {
      setFocusOther && setFocusOther(false);
      onUpdate(
        {
          ...editor!.getJSON(),
          'content-type': content['content-type'],
          docId: content['docId'],
        },
        index,
      );
    },
  });

  const { memopadCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const items: MenuProps['items'] = [
    {
      key: ContentControl.MOVE_UP,
      label: memopadCmsData?.memopad_tooltip.move_up,
      disabled: index === 0,
    },
    {
      key: ContentControl.REMOVE,
      label: memopadCmsData?.memopad_tooltip.remove,
    },
    {
      key: ContentControl.MOVE_DOWN,
      label: memopadCmsData?.memopad_tooltip.move_down,
      disabled: index === maxIndex,
    },
  ];

  const editorType = (contentType: string) => {
    switch (contentType) {
      case 'text':
        return ContentType.TEXT;
      case 'table':
        return ContentType.TABLE;
      case 'list':
        return ContentType.LIST;
      default:
        return ContentType.TEXT;
    }
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case ContentControl.MOVE_UP:
        onMoveUp(index);
        return;
      case ContentControl.REMOVE:
        onRemove(index);
        return;
      case ContentControl.MOVE_DOWN:
        onMoveDown(index);
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsNew(false);
    }, 1000);
  }, []);
  if (!editor) {
    return null;
  }
  if (editor && index === maxIndex && focusOther) {
    editor.commands.focus();
    setFocusOther && setFocusOther(false);
  }

  const setLink = (url: string) => {
    setShowLinkPopover(false);
    if (!rangeSelection) return;

    if (url === null) return;

    if (url === '') return;

    if (!/^https?:\/\//.test(url)) return;

    editor
      .chain()
      .setTextSelection({
        from: rangeSelection.from,
        to: rangeSelection.to,
      })
      .extendMarkRange('link')
      .setLink({ href: url, target: '_blank' })
      .run();
    setRangeSelection(null);
  };

  const handleHeaderChange = (value: string) => {
    switch (value) {
      case 'h1':
        editor.chain().focus().toggleHeading({ level: 1 }).run();
        break;
      case 'h2':
        editor.chain().focus().toggleHeading({ level: 2 }).run();
        break;
      case 'h3':
        editor.chain().focus().toggleHeading({ level: 3 }).run();
        break;
      default:
        if (editor.isActive('heading', { level: 1 })) {
          editor.chain().focus().toggleHeading({ level: 1 }).run();
        }

        if (editor.isActive('heading', { level: 2 })) {
          editor.chain().focus().toggleHeading({ level: 2 }).run();
        }
        if (editor.isActive('heading', { level: 3 })) {
          editor.chain().focus().toggleHeading({ level: 3 }).run();
        }
        break;
    }
  };

  const LinkPopoverContent = (
    <Input
      autoFocus={true}
      style={{ width: '300px' }}
      placeholder={memopadCmsData?.memopad_tooltip.put_link_here}
      value={defaultLink}
      onChange={(evt) => {
        setDefaultLink((evt.target as HTMLInputElement).value);
      }}
      onKeyPress={(evt) => {
        if (evt.code === 'Enter') {
          setLink((evt.target as HTMLInputElement).value);
        }
      }}
      onBlur={(evt) => setLink(evt.target.value)}
    />
  );

  return (
    <div
      className={classNames(
        'memopadElement',
        content['content-type'],
        content['docId'],
      )}
    >
      {projectDetails?.is_archived === false &&
        Boolean(disableEditor) === false && (
          <Dropdown
            placement="bottomLeft"
            menu={{ items, onClick }}
            trigger={['click']}
          >
            <span className={'cmnIcon blueMore'} />
          </Dropdown>
        )}

      {content['content-type'] === 'text' ||
      content['content-type'] === 'table' ||
      content['content-type'] === 'list' ? (
        <div
          className="editorContainer"
          onMouseEnter={() => {
            if (
              editor?.isFocused &&
              projectDetails?.is_archived === false &&
              Boolean(disableEditor) === false
            ) {
              setIsEditMode(true);
            }
          }}
          onMouseLeave={() => setIsEditMode(false)}
        >
          <RichTextEditor editor={editor}>
            <>
              {editor && (
                <BubbleMenu
                  editor={editor}
                  tippyOptions={{
                    appendTo: () => {
                      const parentElement = document.querySelector(
                        '.createNewSecMainContent',
                      );
                      return parentElement || document.body;
                    },
                  }}
                >
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.Underline />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>
                </BubbleMenu>
              )}
              <RichTextEditor.Content
                onKeyDown={(e) => {
                  if (
                    !editor.isActive('table') &&
                    editorType(content['content-type']) === ContentType.TABLE
                  )
                    e.preventDefault();
                }}
              />
            </>
          </RichTextEditor>
          {isEditMode &&
          editorType(content['content-type']) !== ContentType.LIST &&
          editorType(content['content-type']) !== ContentType.TEXT ? (
            <div
              className={classNames('tiptapEditorButtonWrapper', {
                focused: editor.isFocused,
              })}
            >
              {/* {editorType(content["content-type"]) === ContentType.TEXT ? (
                <>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.bold}
                  >
                    <button
                      onClick={() => {
                        editor?.commands.focus();
                        editor.chain().focus().toggleBold().run();
                      }}
                      disabled={
                        !editor.can().chain().focus().toggleBold().run()
                      }
                      className={editor.isActive("bold") ? "is-active" : ""}
                    >
                      <BoldOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.italic}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleItalic().run()
                      }
                      disabled={
                        !editor.can().chain().focus().toggleItalic().run()
                      }
                      className={editor.isActive("italic") ? "is-active" : ""}
                    >
                      <ItalicOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.underline}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                      }
                      className={
                        editor.isActive("underline") ? "is-active" : ""
                      }
                    >
                      <UnderlineOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.strikethrough}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleStrike().run()
                      }
                      disabled={
                        !editor.can().chain().focus().toggleStrike().run()
                      }
                      className={editor.isActive("strike") ? "is-active" : ""}
                    >
                      <StrikethroughOutlined />
                    </button>
                  </Tooltip>
                  <select
                    onChange={(evt) => handleHeaderChange(evt.target.value)}
                  >
                    <option value="normal">Normal</option>
                    <option
                      value="h1"
                      selected={editor.isActive("heading", { level: 1 })}
                    >
                      H1
                    </option>
                    <option
                      value="h2"
                      selected={editor.isActive("heading", { level: 2 })}
                    >
                      H2
                    </option>
                    <option
                      value="h3"
                      selected={editor.isActive("heading", { level: 3 })}
                    >
                      H3
                    </option>
                  </select>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.bullet_list}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleBulletList().run()
                      }
                      className={
                        editor.isActive("bulletList") ? "is-active" : ""
                      }
                    >
                      <UnorderedListOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.order_list}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleOrderedList().run()
                      }
                      className={
                        editor.isActive("orderedList") ? "is-active" : ""
                      }
                    >
                      <OrderedListOutlined />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.link}
                  >
                    <Popover
                      placement="right"
                      open={showLinkPopover}
                      content={LinkPopoverContent}
                    >
                      <button
                        disabled={editor.state.selection.empty}
                        onClick={() => {
                          const state = editor.state;
                          const { from, to } = state.selection;
                          setDefaultLink(editor.getAttributes("link").href);
                          setRangeSelection({
                            from,
                            to,
                          });
                          setShowLinkPopover(!showLinkPopover);
                          editor.commands.unsetLink();
                        }}
                        className={editor.isActive("link") ? "is-active" : ""}
                      >
                        <LinkOutlined />
                      </button>
                    </Popover>
                  </Tooltip>
                </>
              ) : null} */}

              {editorType(content['content-type']) === ContentType.TABLE ? (
                <>
                  {/* <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.insert_table}
                  >
                    <button
                      disabled={editor.isActive('table')}
                      onClick={() =>
                        editor
                          .chain()
                          .focus()
                          .insertTable({
                            rows: 3,
                            cols: 3,
                            withHeaderRow: true,
                          })
                          .run()
                      }
                    >
                      <img src={insertTable} alt="insert table" />
                    </button>
                  </Tooltip> */}

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.add_column_before}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().addColumnBefore().run()
                      }
                    >
                      <img src={addColumnBefore} alt="" />
                    </button>
                  </Tooltip>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.add_column_after}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().addColumnAfter().run()
                      }
                    >
                      <img src={addColumnAfter} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.delete_column}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().deleteColumn().run()
                      }
                    >
                      <img src={deleteColumn} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.add_row_above}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().addRowBefore().run()
                      }
                    >
                      <img src={addRowBefore} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.add_row_below}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() => editor.chain().focus().addRowAfter().run()}
                    >
                      <img src={addRowAfter} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.delete_row}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() => editor.chain().focus().deleteRow().run()}
                    >
                      <img src={deleteRow} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.delete_table}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() => editor.chain().focus().deleteTable().run()}
                    >
                      <img src={deleteTable} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.merge_cell}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() => editor.chain().focus().mergeCells().run()}
                    >
                      <img src={mergeCells} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.split_cell}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() => editor.chain().focus().splitCell().run()}
                    >
                      <img src={splitCell} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.toggle_header_column}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().toggleHeaderColumn().run()
                      }
                    >
                      <img src={toggleHeaderColumn} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.toggle_header_row}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().toggleHeaderRow().run()
                      }
                    >
                      <img src={toggleHeaderRow} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.toggle_header_cell}
                  >
                    <button
                      disabled={!editor.isActive('table')}
                      onClick={() =>
                        editor.chain().focus().toggleHeaderCell().run()
                      }
                    >
                      <img src={toggleHeaderCell} alt="" />
                    </button>
                  </Tooltip>

                  {/*<button onClick={() => editor.chain().focus().mergeOrSplit().run()}>*/}

                  {/*  <img src={mergeOrSplit} alt="" />*/}

                  {/*</button>*/}

                  {/*<button*/}

                  {/*  onClick={() =>*/}

                  {/*    editor.chain().focus().setCellAttribute("colspan", 2).run()*/}

                  {/*  }*/}

                  {/*>*/}

                  {/*  <img src={setCellAttribute} alt="" />*/}

                  {/*</button>*/}

                  {/*<button onClick={() => editor.chain().focus().fixTables().run()}>*/}

                  {/*  <img src={fixTables} alt="" />*/}

                  {/*</button>*/}

                  {/*<button onClick={() => editor.chain().focus().goToNextCell().run()}>*/}

                  {/*  <img src={goToNextCell} alt="" />*/}

                  {/*</button>*/}

                  {/*<button onClick={() => editor.chain().focus().goToPreviousCell().run()}>*/}

                  {/*  <img src={goToPreviousCell} alt="" />*/}

                  {/*</button>*/}
                </>
              ) : null}

              {/* {ContentType.LIST === editorType(content["content-type"]) ? (
                <>
                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip?.toggle_task_list}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().toggleTaskList().run()
                      }
                      className={editor.isActive("taskList") ? "is-active" : ""}
                    >
                      <img src={toggleTaskList} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.split_list_item}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().splitListItem("taskItem").run()
                      }
                      disabled={!editor.can().splitListItem("taskItem")}
                    >
                      <img src={splitListItem} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.sink_list_item}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().sinkListItem("taskItem").run()
                      }
                      disabled={!editor.can().sinkListItem("taskItem")}
                    >
                      <img src={sinkListItem} alt="" />
                    </button>
                  </Tooltip>

                  <Tooltip
                    placement="bottom"
                    title={memopadCmsData?.memopad_tooltip.lift_list_item}
                  >
                    <button
                      onClick={() =>
                        editor.chain().focus().liftListItem("taskItem").run()
                      }
                      disabled={!editor.can().liftListItem("taskItem")}
                    >
                      <img src={liftListItem} alt="" />
                    </button>
                  </Tooltip>
                </>
              ) : null} */}

              {/* <Tooltip
                placement="bottom"
                title={memopadCmsData?.memopad_tooltip.undo}
              >
                <button
                  onClick={() => editor.chain().focus().undo().run()}
                  disabled={!editor.can().chain().focus().undo().run()}
                >
                  <UndoOutlined />
                </button>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={memopadCmsData?.memopad_tooltip.redo}
              >
                <button
                  onClick={() => editor.chain().focus().redo().run()}
                  disabled={!editor.can().chain().focus().redo().run()}
                >
                  <RedoOutlined />
                </button>
              </Tooltip> */}

              {/*<button*/}

              {/*  onClick={() => editor.chain().focus().setColor('#958DF1').run()}*/}

              {/*  className={editor.isActive('textStyle', {color: '#958DF1'}) ? 'is-active' : ''}*/}

              {/*>*/}

              {/*  purple*/}

              {/*</button>*/}
            </div>
          ) : null}
        </div>
      ) : null}
      {content['content-type'] === 'file' ? (
        <FileUploader
          onChange={(evt) =>
            onUpdate(
              {
                ...content,
                content: evt,
              },
              index,
            )
          }
          content={content}
          organizationId={organizationId}
          projectId={projectId}
        />
      ) : null}
      {content['content-type'] === 'link' ? (
        <MemopadLink
          onChange={(evt) =>
            onUpdate(
              {
                ...content,
                content: evt,
              },
              index,
            )
          }
          content={content}
          disableEditor={disableEditor}
          focusLink={focusLink}
          setFocusLink={setFocusLink}
        />
      ) : null}
    </div>
  );
};

export default TiptapEditor;
