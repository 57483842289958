import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  IProjectState,
  IMemopad,
  IProjectSections,
} from '../../../../../../models/interface';
import {
  useProjectDashboardStore,
  useUserStore,
} from '../../../../../../stores';
import BrowserStorageService from '../../../../../../services/browserStorage.service';
import projectService from '../../../../../../services/ProjectService/project.service';
import { Tooltip, Divider, Popover, Skeleton } from 'antd';
import {
  colorChanger,
  findCollapseStateFromStorage,
  getCurrentOrganization,
  storeSectionListStatusInLocalStorage,
} from 'utils/commonFunctions';
import cryptoRandomString from 'crypto-random-string';
import MeatBallMenu from '../MeatBallMenu';
import { loggerService, notificationService } from 'services';
import {
  NotificationEventType,
  SectionType,
  SectionTypeForViewPort,
} from 'models/enums/notification';
import TiptapEditor from './TiptapEditor';
import { JSONContent } from '@tiptap/core/src/types';
import {
  AlignLeftOutlined,
  CheckSquareOutlined,
  CloudOutlined,
  TableOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import {
  useFetchNewPermission,
  useIsInViewport,
} from 'components/sharedComponents/hooks';

const DEFAULT_CONTENT = {
  type: 'doc',
  'content-type': 'text',
  is_new: true,
  content: [
    {
      type: 'paragraph',
    },
  ],
};

const TABLE_CONTENT = {
  type: 'doc',
  'content-type': 'table',
  is_new: true,
  content: [
    {
      type: 'table',
      content: [
        {
          type: 'tableRow',
          content: [
            {
              type: 'tableHeader',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableHeader',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableHeader',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
          ],
        },
        {
          type: 'tableRow',
          content: [
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
          ],
        },
        {
          type: 'tableRow',
          content: [
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
            {
              type: 'tableCell',
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: null,
              },
              content: [
                {
                  type: 'paragraph',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const LIST_CONTENT = {
  type: 'doc',
  'content-type': 'list',
  is_new: true,
  content: [
    {
      type: 'taskList',
      content: [
        {
          type: 'taskItem',
          attrs: {
            checked: true,
          },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'Checklist 1',
                },
              ],
            },
          ],
        },
        {
          type: 'taskItem',
          attrs: {
            checked: false,
          },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'Checklist 2',
                },
              ],
            },
          ],
        },
        {
          type: 'taskItem',
          attrs: {
            checked: false,
          },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'Checklist 3',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'paragraph',
    },
  ],
};

const FILE_CONTENT = {
  type: 'doc',
  'content-type': 'file',
  is_new: true,
  content: {},
};

const LINK_CONTENT = {
  type: 'doc',
  'content-type': 'link',
  is_new: true,
  content: {},
};

const MemoPad: React.FC<{
  sectionId: string | number;
  currentProjectDetails: IProjectSections;
  // socket: any;
  projectColor?: string;
  refDiv: React.RefObject<HTMLDivElement>;
}> = ({
  sectionId,
  currentProjectDetails,
  // socket,
  projectColor = '#dbe8ff',
  refDiv,
}) => {
  const { hasPermissions } = useRbac();
  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const [focusLink, setFocusLink] = useState(false);
  const [focusOther, setFocusOther] = useState(false);
  const [tempMemoPadValue, setTempMemoPadValue] = useState<any[]>([]);
  const [sectionDetails, setSectionDetails] = useState<IMemopad>({
    memopad_data: [],
    section_name: '',
    section_id: currentProjectDetails.section_id,
    section_rank: currentProjectDetails.section_rank,
    is_private: false,
    section_type_id: 3,
    pack_id: 1,
    created_by: '',
  });
  const {
    projectDetails,
    memopadCmsData,
    setProjectDetails,
    setProjectActiveTab,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const memopadContainerRef = useRef<HTMLDivElement>(null);
  const [sectionName, setSectionName] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [sectionToggleState, setSectionToggleState] = useState<
    'expanded' | 'collapsed'
  >('expanded');
  const [showPopOver, setShowPopOver] = useState<boolean>(false);
  const [disableEditor, setDisableEditor] = useState<boolean>(false);
  const browserStorage = BrowserStorageService.getInstance();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const urlParams = useParams();

  const memoPadSection = useRef(null);
  const isMemoPadSectionInViewport = useIsInViewport(memoPadSection);

  const { fetchNewPermission } = useFetchNewPermission();

  const checkCollaborator = () => {
    if (
      hasPermissions(
        [ERbacPermissions.PROJECT_SECTION_EDIT],
        projectDetails?.associated_role_id,
      ) === false
    ) {
      setDisableEditor(true);
    } else {
      setDisableEditor(false);
    }
  };

  const initializeProjectSettings = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;

    let collapseState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId,
      orgId,
    );
    storeSectionListStatusInLocalStorage(
      sectionDetails,
      userDetails.user_id.toString(),
    );
    setSectionToggleState((prev) =>
      collapseState && collapseState.collapsed ? 'collapsed' : 'expanded',
    );
  };

  const sectionNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.currentTarget?.value;
    setSectionName(value);
  };

  const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sectionNameOnBlurHandler(event);
    }
  };

  const handleSectionToggle = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;

    let collapseState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId,
      orgId,
    );
    setSectionToggleState((prev) =>
      collapseState && collapseState.collapsed ? 'expanded' : 'collapsed',
    );
    if (collapseState && collapseState.collapsed) {
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
        false,
        undefined,
        undefined,
      );
    } else {
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
        true,
        undefined,
        undefined,
      );
    }
  };

  useEffect(() => {
    if (typeof sectionId === 'string') {
      setIsEditingSectionName(true);
    } else {
      setIsEditingSectionName(false);
    }
  }, [sectionId]);

  useEffect(() => {
    checkCollaborator();
  }, [projectDetails]);

  const processMemopadData = (response: any) => {
    if (response.data?.memopad_data) {
      if (response.data.memopad_data instanceof Array) {
        let data = response.data.memopad_data.map((e: any) => ({
          ...e,
          docId:
            e.docId ?? cryptoRandomString({ length: 10, type: 'alphanumeric' }),
        }));
        return data;
      }
    }
    return [];
  };

  const getSectionDetails = async () => {
    setIsLoading(true);
    const payload = {
      project_id: urlParams.projectId!,
      organization_id: getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!,
      section_id: sectionId.toString(),
    };
    try {
      const response = await projectService.getMemopadDetails(payload);
      if (response.messageId > 0) {
        const checkIsDataDoesHaveId = processMemopadData(response);
        setSectionName(response.data.section_name!);
        setSectionDetails(response.data);
        setTempMemoPadValue(checkIsDataDoesHaveId);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch tasklist details',
        payload: payload,
      });
    }
  };

  // useEffect(() => {
  //   if (
  //     isMemoPadSectionInViewport &&
  //     sectionDetails.memopad_data?.length === 0
  //   ) {
  //     /* Comment the if clause for the PRODUCTION release on 21st Feb 2024 and empty the dependency array */
  //     if (typeof sectionDetails.section_id != "string") {
  //       getSectionDetails();
  //     }
  //   }
  // }, [memoPadSection, isMemoPadSectionInViewport]);

  useEffect(() => {
    initializeProjectSettings();
    if (typeof sectionDetails.section_id != 'string') {
      getSectionDetails();
    }
  }, []);

  useEffect(() => {
    if (isEditingSectionName) {
      setSectionName(sectionName ?? '');
    }
  }, [isEditingSectionName]);

  useEffect(() => {
    if (!isEditingSectionName) {
      setSectionName(sectionName ?? '');
    }
  }, [currentProjectDetails]);

  const sectionNameOnBlurHandler = async (
    _event:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    setIsEditingSectionName(false);
    setSectionName((prev) => prev?.trim());
    if (projectDetails?.project_id && projectDetails?.sections) {
      const oldSection = projectDetails.sections.find(
        (eachSection) => eachSection.section_id === sectionDetails.section_id,
      );

      if (typeof oldSection?.section_id == 'string' && sectionName === '') {
        const tempValue = {
          ...projectDetails,
          sections: projectDetails?.sections?.filter(
            (eachSection) => eachSection.section_id !== oldSection.section_id,
          ),
        };
        setProjectDetails(tempValue);
        setProjectActiveTab(projectDetails.sections?.[1]?.section_id);
      } else if (
        typeof oldSection?.section_id == 'string' &&
        sectionName !== ''
      ) {
        await createNewSection(oldSection);
      } else if (
        typeof oldSection?.section_id === 'number' &&
        sectionName?.trim() !== '' &&
        sectionDetails.section_name !== sectionName
      ) {
        await updateExistingSection(oldSection);
      }
    }
  };
  // @ts-ignore ignoring old type section
  const createNewSection = async (oldSection) => {
    const userDetails = browserStorage.getUserDetails();
    setIsLoading(true);
    try {
      const response = await projectService.createNewSection({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        project_id: projectDetails?.project_id!,
        section_type_id: 3,
        section_name: sectionName,
        section_rank: oldSection.section_rank,
      });
      if (response.data.data) {
        const tempSectionDetail = { ...sectionDetails };
        tempSectionDetail.section_name = sectionName;
        tempSectionDetail.section_id = response.data.data;
        setProjectDetails({
          ...projectDetails,
          sections: projectDetails?.sections!.map((eachSection) => {
            const _section = eachSection;
            if (_section.section_id === sectionId) {
              _section.section_id = response.data.data;
              _section.section_name = sectionName;
            }
            return _section;
          }),
        });
        setProjectActiveTab(response.data.data);
        setSectionDetails(tempSectionDetail);
        setIsLoading(false);

        // notificationService.sendSectionUpdateNotification(socket, {
        //   eventType: NotificationEventType.ADD_NEW_SECTION,
        //   eventValue: "",
        //   sectionType: SectionType.SIMPLE_TASK_LIST,
        //   currentOrganizationId: getCurrentOrganization(
        //     org_key,
        //     userDetails?.organization_info
        //   )?.organization_id,
        //   currentProjectId: projectDetails?.project_id,
        //   currentSectionId: "",
        //   currentUserId: userDetails.user_id,
        //   currentUserName: userDetails.full_name
        //     ? userDetails.full_name
        //     : userDetails.email,
        // });
      }
    } catch (error: any) {
      fetchNewPermission(
        org_key,
        memopadCmsData?.lbl_error_message_permission_denied,
      );
    } finally {
      setIsLoading(false);
    }
  };

  // @ts-ignore ignoring old type section
  const updateExistingSection = async (oldSection) => {
    const userDetails = browserStorage.getUserDetails();
    let orgID = getCurrentOrganization(org_key, userDetails.organization_info)
      ?.organization_id!;
    setIsLoading(true);

    try {
      const result = await projectService.updateSection({
        organizationId: orgID,
        project_id: projectDetails?.project_id!,
        userId: userDetails?.user_id,
        sections: [
          {
            section_id: sectionDetails.section_id,
            section_name:
              sectionName !== '' ? sectionName : sectionDetails.section_name,
          },
        ],
      });
      if (result.messageId === 1) {
        setIsEditingSectionName(false);
        if (
          projectDetails !== undefined &&
          projectDetails?.project_id !== undefined &&
          orgID !== undefined
        ) {
          let collapseState = findCollapseStateFromStorage(
            sectionDetails,
            userDetails.user_id.toString(),
            projectDetails?.project_id.toString(),
            orgID.toString(),
          );
          setSectionToggleState((prev) =>
            collapseState && collapseState.collapsed ? 'collapsed' : 'expanded',
          );
        } else {
          setSectionToggleState('expanded');
        }

        setProjectDetails({
          ...projectDetails,
          sections: projectDetails?.sections?.map((eachSection) => {
            const _section = eachSection;
            delete _section.is_edit_mode;
            if (oldSection.section_id === eachSection.section_id) {
              return {
                ..._section,
                section_name: sectionName,
              };
            }
            return _section;
          }),
        });

        // notificationService.sendSectionUpdateNotification(socket, {
        //   eventType: NotificationEventType.UPDATE_SECTION_NAME,
        //   eventValue: sectionName,
        //   sectionType: SectionType.SIMPLE_TASK_LIST,
        //   currentOrganizationId: getCurrentOrganization(
        //     org_key,
        //     userDetails?.organization_info
        //   )?.organization_id,
        //   currentProjectId: projectDetails?.project_id,
        //   currentSectionId: sectionDetails.section_id,
        //   currentUserId: userDetails.user_id,
        //   currentUserName: userDetails.full_name
        //     ? userDetails.full_name
        //     : userDetails.email,
        // });
      }
    } catch (error: any) {
      fetchNewPermission(
        org_key,
        memopadCmsData?.lbl_error_message_permission_denied,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateMemopad = async (data: Object) => {
    if (projectDetails?.sections) {
      const userDetails = browserStorage.getUserDetails();
      let orgID = getCurrentOrganization(org_key, userDetails.organization_info)
        ?.organization_id!;
      try {
        await projectService.updateSection({
          organizationId: orgID,
          project_id: projectDetails?.project_id!,
          sections: [
            {
              section_id: +sectionDetails.section_id,
              section_name:
                sectionName === '' ? sectionDetails.section_name : sectionName,
              memopad_data: data,
            },
          ],
        });

        setIsEditingSectionName(false);
        if (
          projectDetails !== undefined &&
          projectDetails?.project_id !== undefined &&
          orgID !== undefined
        ) {
          let collapseState = findCollapseStateFromStorage(
            sectionDetails,
            userDetails.user_id.toString(),
            projectDetails?.project_id.toString(),
            orgID.toString(),
          );
          setSectionToggleState((prev) =>
            collapseState && collapseState.collapsed ? 'collapsed' : 'expanded',
          );
        } else {
          setSectionToggleState('expanded');
        }
      } catch (error: any) {
        fetchNewPermission(
          org_key,
          memopadCmsData?.lbl_error_message_permission_denied,
        );
      }
    }
  };

  const updateContentBlock = (data: JSONContent, index: number) => {
    const tempData = [...tempMemoPadValue];
    tempData[index] = data;
    setTempMemoPadValue(tempData);
    updateMemopad(tempData);
  };

  const moveUpContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue];
    const tempComponent = tempData[index - 1];
    const targetContent = tempData[index];
    tempData[index - 1] = targetContent;
    tempData[index] = tempComponent;
    setTempMemoPadValue(tempData);
    updateMemopad(tempData);
  };

  const moveDownContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue];
    const tempComponent = tempData[index + 1];
    const targetContent = tempData[index];
    tempData[index + 1] = targetContent;
    tempData[index] = tempComponent;
    setTempMemoPadValue(tempData);
    updateMemopad(tempData);
  };

  const removeContentBlock = (index: number) => {
    const tempData = [...tempMemoPadValue];
    tempData.splice(index, 1);
    setTempMemoPadValue(tempData);
    updateMemopad(tempData);
  };

  const showContent = () => {
    if (typeof sectionDetails.section_id === 'string') {
      return false;
    }
    return !isEditingSectionName;
  };

  const MemopadContent = (
    <div
      className="memopad-feature-container"
      onMouseLeave={() => {
        setShowPopOver(false);
      }}
    >
      <div
        className="memopad-feature-item"
        onClick={() => {
          setShowPopOver(false);
          setFocusOther(true);
          const tempContent = [...tempMemoPadValue];
          tempContent.push({
            ...DEFAULT_CONTENT,
            docId: cryptoRandomString({ length: 10, type: 'alphanumeric' }),
          });
          setTempMemoPadValue(tempContent);
          updateMemopad(tempContent);
        }}
        onKeyDown={() => {}}
      >
        <AlignLeftOutlined />
        <span className="memopad-feature-label">
          {memopadCmsData?.lbl_tool_name_text}
        </span>
      </div>
      <div
        className="memopad-feature-item"
        onClick={() => {
          setShowPopOver(false);
          setFocusLink(true);
          const tempContent = [...tempMemoPadValue];
          tempContent.push({
            ...LINK_CONTENT,
            docId: cryptoRandomString({ length: 10, type: 'alphanumeric' }),
          });
          setTempMemoPadValue(tempContent);
          updateMemopad(tempContent);
        }}
        onKeyDown={() => {}}
      >
        <CloudOutlined />
        <span className="memopad-feature-label">
          {memopadCmsData?.lbl_tool_name_link}
        </span>
      </div>
      <div
        className="memopad-feature-item"
        onClick={() => {
          setShowPopOver(false);
          setFocusOther(true);
          const tempContent = [...tempMemoPadValue];
          tempContent.push({
            ...FILE_CONTENT,
            docId: cryptoRandomString({ length: 10, type: 'alphanumeric' }),
          });
          setTempMemoPadValue(tempContent);
          delete tempContent[tempContent.length - 1].is_new;
          updateMemopad(tempContent);
        }}
        onKeyDown={() => {}}
      >
        <UploadOutlined />
        <span className="memopad-feature-label">
          {memopadCmsData?.lbl_tool_name_attachment}
        </span>
      </div>
      <div
        className="memopad-feature-item"
        onClick={() => {
          setShowPopOver(false);
          setFocusOther(true);
          const tempContent = [...tempMemoPadValue];
          tempContent.push({
            ...TABLE_CONTENT,
            docId: cryptoRandomString({ length: 10, type: 'alphanumeric' }),
          });
          setTempMemoPadValue(tempContent);
          updateMemopad(tempContent);
        }}
        onKeyDown={() => {}}
      >
        <TableOutlined />
        <span className="memopad-feature-label">
          {memopadCmsData?.lbl_tool_name_table}
        </span>
      </div>
      <div
        className="memopad-feature-item"
        onClick={() => {
          setShowPopOver(false);
          setFocusOther(true);
          const tempContent = [...tempMemoPadValue];
          tempContent.push({
            ...LIST_CONTENT,
            docId: cryptoRandomString({ length: 10, type: 'alphanumeric' }),
          });
          setTempMemoPadValue(tempContent);
          updateMemopad(tempContent);
        }}
        onKeyDown={() => {}}
      >
        <CheckSquareOutlined />
        <span className="memopad-feature-label">
          {memopadCmsData?.lbl_tool_name_checklist}
        </span>
      </div>
    </div>
  );

  return isMemoPadSectionInViewport ? (
    <div
      className="createNewSecMainContent"
      id={`${SectionTypeForViewPort.MEMOPAD}-${sectionId}`}
      ref={memoPadSection}
    >
      {isLoading ? (
        <Skeleton active loading={isLoading} />
      ) : (
        <>
          <div className="firstTopic">
            <div className="taskNameHeadingPane">
              <div className="itemNameBlk">
                <div
                  className="nameOuterBlk widgetBlockActive"
                  style={{
                    backgroundColor:
                      colorChanger(
                        -0.5,
                        projectColor.trim(),
                        undefined,
                        true,
                      ) ?? projectColor,
                  }}
                >
                  {/* <Tooltip
                    title={memopadCmsData?.lbl_section_view_toggle_tooltip}
                    color={'#2E364C'}
                    placement="bottom"
                  >
                    <span
                      className={`sidePanelIcon ${
                        sectionToggleState === 'expanded'
                          ? 'arrow-down'
                          : 'arrow-right'
                      }`}
                      color={'#2E364C'}
                      onClick={handleSectionToggle}
                      onKeyDown={() => {}}
                    />
                  </Tooltip> */}
                  {isEditingSectionName &&
                  projectDetails?.is_archived === false ? (
                    <Rbac
                      allowedPermissions={[
                        ERbacPermissions.PROJECT_SECTION_EDIT,
                      ]}
                      project_role_id={projectDetails?.associated_role_id}
                    >
                      <div className="auto-grow-input">
                        <input
                          className="section-text-input"
                          placeholder={
                            memopadCmsData?.lbl_new_section_default_name
                          }
                          onBlur={sectionNameOnBlurHandler}
                          onChange={sectionNameChangeHandler}
                          onKeyDown={saveOnEnter}
                          value={sectionName}
                          autoFocus={true}
                          maxLength={50}
                        />
                        <span className="auto-grow-input-hidden">
                          {sectionName}
                        </span>
                      </div>
                    </Rbac>
                  ) : (
                    <span
                      className="section-text-span"
                      onClick={() => {
                        if (projectDetails?.is_archived === false) {
                          hasPermissions(
                            [ERbacPermissions.PROJECT_SECTION_EDIT],
                            projectDetails?.associated_role_id,
                          ) && setIsEditingSectionName(true);
                        }
                      }}
                      onKeyDown={() => {}}
                    >
                      {sectionName}
                    </span>
                  )}
                  {!isEditingSectionName && sectionDetails.is_private ? (
                    <span className="cmnIcon sectionPrivacy lock "></span>
                  ) : (
                    <></>
                  )}
                </div>
                {!isEditingSectionName && (
                  <MeatBallMenu
                    sectionType="memo-pad"
                    sectionDetails={sectionDetails!}
                    // socket={socket}
                    getSectionDetails={getSectionDetails}
                    sectionList={projectDetails?.sections!}
                    setSectionDetails={setSectionDetails}
                    refDiv={refDiv}
                  />
                )}
              </div>
            </div>
          </div>
          {showContent() ? (
            <div ref={memopadContainerRef} className="memoPadTipTap">
              {tempMemoPadValue.map((e, i) => (
                <TiptapEditor
                  key={e.docId}
                  content={e}
                  index={i}
                  memopadCmsData={memopadCmsData}
                  maxIndex={tempMemoPadValue.length - 1}
                  onMoveUp={moveUpContentBlock}
                  onMoveDown={moveDownContentBlock}
                  onRemove={removeContentBlock}
                  onUpdate={updateContentBlock}
                  projectId={projectDetails?.project_id!}
                  organizationId={
                    +getCurrentOrganization(
                      org_key,
                      userDetails?.organization_info,
                    )?.organization_id!
                  }
                  disableEditor={disableEditor}
                  focusLink={focusLink}
                  setFocusLink={setFocusLink}
                  focusOther={focusOther}
                  setFocusOther={setFocusOther}
                />
              ))}
            </div>
          ) : null}
          {showContent() &&
          projectDetails?.is_archived === false &&
          disableEditor === false ? (
            <div className={'userInformation'}>
              <div className="memopadEmptyContainer">
                <Divider className="memopadDivider" />
                <Popover
                  open={showPopOver}
                  placement="bottom"
                  title={''}
                  content={MemopadContent}
                  overlayClassName="customAntPopover"
                >
                  <div
                    className="memopadPlusIcon"
                    onClick={() => {
                      setShowPopOver(!showPopOver);
                    }}
                    onKeyDown={() => {}}
                  />
                </Popover>
              </div>

              {memopadCmsData?.lbl_new_section_user_information}
            </div>
          ) : null}
        </>
      )}
    </div>
  ) : (
    <div
      className="createNewSecMainContent"
      ref={memoPadSection}
      id={`${SectionTypeForViewPort.MEMOPAD}-${sectionId}`}
      style={{ minHeight: '150px' }}
    ></div>
  );
};

export default MemoPad;
