import React, { useState } from 'react';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import copyIcon from '../../../../assets/images/copy-outline-icon.svg';
import copiedIcon from '../../../../assets/e-images/done-icon.svg';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import downArrowGrad from '../../../../assets/images/down-arrow-grad.svg';
import chrome from '../../../../assets/images/chrome.png';
import firefox from '../../../../assets/images/firefox.png';
import edge from '../../../../assets/images/microsoft.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFeedbackStore } from 'stores/feedback.store';
import { useProjectDashboardStore } from 'stores';
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
interface CodeProps {
  script: string;
  envId: number;
}

const GenerateCode: React.FC<CodeProps> = ({ script, envId }) => {
  const [code, setCode] = useState(script);
  const [copied, setCopied] = useState(false);
  const [selectedBrowser, setSelectedBrowser] = useState(1);
  const { pncAddEnvSpecificCmsData } = usePncCmsDataStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { setEnvValue } = useFeedbackStore((state: any) => state);
  const [, setQuery] = useSearchParams();
  const { projectDetails } = useProjectDashboardStore();

  const org_key = location.pathname.split('/')[2];

  const handleChange = (value: any) => {
    setCode(value);
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    });
  };

  const getBrowserExtensionName = () => {
    switch (selectedBrowser) {
      case 1:
        return 'ChromeExtension';
      case 2:
        return 'FirefoxExtension';
      case 3:
        return 'EdgeExtension';
      default:
        return null;
    }
  };

  return (
    <>
      <div className="headerBlk">
        <h2>{pncAddEnvSpecificCmsData?.gen_code_heading}</h2>
        <b>{pncAddEnvSpecificCmsData?.gen_code_steps?.lbl_step} - 1 </b>
        <p>
          {pncAddEnvSpecificCmsData?.gen_code_steps?.step_1_pre}{' '}
          <span className="htmlTag">&lt;body&gt;</span>{' '}
          {pncAddEnvSpecificCmsData?.gen_code_steps?.step_1_post}.
        </p>
      </div>
      <div className="codeAccordion">
        <div className="accordionHead">
          <h3>HTML</h3>
          <button type="button" className="copyCode" onClick={handleCopyClick}>
            {copied ? (
              <span>
                <img src={copiedIcon} alt="" />
              </span>
            ) : (
              <span>
                <img src={copyIcon} alt="" />
              </span>
            )}
          </button>
        </div>
        <div className="accordionContent">
          <ControlledEditor
            value={code}
            onBeforeChange={handleChange}
            options={{
              lineNumbers: false,
              mode: 'javascript',
              theme: 'default',
              readOnly: true,
            }}
          />
        </div>
      </div>
      <img src={downArrowGrad} alt="" className="stepArrow" />
      <div>
        <div className="headerBlk downloadExtension">
          <b>{pncAddEnvSpecificCmsData?.gen_code_steps?.lbl_step} - 2 </b>
          <p>{pncAddEnvSpecificCmsData?.gen_code_steps?.step_2}</p>
          <ul className="browsersList">
            <li>
              <input
                type="radio"
                id="pncChromeExtension"
                name="browserType"
                onClick={() => setSelectedBrowser(1)}
              />
              <label htmlFor="pncChromeExtension">
                <img src={chrome} alt="" />
              </label>
            </li>
            <li>
              <input
                type="radio"
                id="pncEdgeExtension"
                name="browserType"
                onClick={() => setSelectedBrowser(2)}
              />
              <label htmlFor="pncEdgeExtension">
                <img src={firefox} alt="" />
              </label>
            </li>
            <li>
              <input
                type="radio"
                id="pncFirefoxExtension"
                name="browserType"
                onClick={() => setSelectedBrowser(3)}
              />
              <label htmlFor="pncFirefoxExtension">
                <img src={edge} alt="" />
              </label>
            </li>
          </ul>
          <a
            href={`../../../../../../BrowserExtensions/${getBrowserExtensionName()}.zip`}
            download
          >
            <button type="button" className="btnStyle1">
              {pncAddEnvSpecificCmsData?.btn_cta_dwnld_ext}
            </button>
          </a>
        </div>
        <img src={downArrowGrad} alt="" className="stepArrow" />
        <div className="headerBlk">
          <b>{pncAddEnvSpecificCmsData?.gen_code_steps?.lbl_step} - 3 </b>
          <p>{pncAddEnvSpecificCmsData?.gen_code_steps?.step_3}</p>
        </div>
        <button
          type="submit"
          className="btnStyle1 buttonLoader"
          onClick={() => {
            setEnvValue(envId);
            setQuery({ env: envId.toString() });

            navigate(
              `/org/${org_key}/project/${projectDetails?.project_id}/pnc/feedback-board?env=${envId}`,
            );
          }}
        >
          {pncAddEnvSpecificCmsData?.btn_cta_continue}
        </button>
      </div>
    </>
  );
};

export default GenerateCode;
