import {
  Outlet,
  Route,
  Routes,
  useParams,
  useNavigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AuthService from 'services/Auth0/authService';
import {
  AnalyticsEventTracker,
  FullPageLoader,
} from 'components/sharedComponents';
import { AnalyticsProvider } from 'contexts/analytics/AnalyticsProvider';
import { AuthenticationGuard } from 'auth/authentication-guard';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { useRbac } from 'auth/rbac/rbac';
import ProfileSettingAndPreferencePage from '../components/pages/ProfileSettingAndPrefrence/ProfileSettingAndPreferencePage';
import { getCurrentOrganization } from 'utils/commonFunctions';

const authService = AuthService.getInstance();

const OrgLayout = () => {
  const { org } = useParams();
  const navigate = useNavigate();
  const { userDetails, currentOrgKey, setCurrentOrgKey } = useRbac();

  const navigateToTheDefaultOrg = () => {
    const defaultOrg = userDetails?.organization_info?.find(
      (eachOrg) => eachOrg.is_default_organization === true,
    );
    if (defaultOrg) {
      navigate(`/org/${defaultOrg.org_key}/dashboard`);
    }
  };

  useEffect(() => {
    if (userDetails?.organization_info) {
      if (org === undefined || org === null) {
        navigateToTheDefaultOrg();
      } else {
        if (currentOrgKey === null) {
          setCurrentOrgKey(org ?? null);
        }
        if (org && currentOrgKey && currentOrgKey !== org) {
          authService.setCurrentOrganization(
            userDetails.organization_info?.find(
              (eachOrg) => eachOrg.org_key === org,
            )?.auth_org_id ?? '',
          );
          setCurrentOrgKey(org ?? null);
          window.location.reload();
        } else {
          authService.clearCurrentOrganization();
        }
      }
    }
  }, [org]);

  return <React.Fragment>{org && <Outlet />}</React.Fragment>;
};

export const useOrganizationRoutes = () => {
  const { org } = useParams();
  const [organizationBaseRouteUrl, setOrganizationBaseRouteUrl] = useState('/');
  useEffect(() => {
    if (org) {
      setOrganizationBaseRouteUrl('/org/' + org);
    }
  }, [org]);

  return { organizationBaseRouteUrl };
};

const OrgDashboardPage = React.lazy(
  () => import('../components/pages/DashboardPage/DashboardPage'),
);
const DashboardProjectListPage = React.lazy(
  () => import('../components/pages/DashboardPage/DashboardProjectListPage'),
);
const ProjectDashboardPage = React.lazy(
  () =>
    import('../components/pages/DashboardPage/Project/ProjectDashboardWrapper'),
);

const NewProjectDashboardPage = React.lazy(
  () => import('../components/pages/DashboardPage/Project/NewProjectboard'),
);
const CompactViewWrapper = React.lazy(
  () => import('../components/pages/DashboardPage/Project/CompactViewWrapper'),
);
const PlannerWrapperPage = React.lazy(
  () => import('../components/pages/Planner/PlannerWrapper'),
);

const MessagesWrapper = React.lazy(
  () => import('../components/pages/Messages/MessagesWrapper'),
);

const UserStoryGenerator = React.lazy(
  () => import('../components/pages/UserStoryGenerator/UserStoryGenerator'),
);

const EpicAndStory = React.lazy(
  () => import('../components/pages/UserStoryGenerator/EpicAndStory'),
);

const ArchiveEpic = React.lazy(
  () => import('../components/pages/UserStoryGenerator/ArchiveEpic'),
);

const PointAndComment = React.lazy(
  () => import('../components/pages/PointAndComment/PointAndCommentWrapper'),
);

export function OrganizationRoutes() {
  return (
    <Routes>
      <Route element={<OrgLayout />}>
        <Route index element={<div>Org Route</div>} />
        <Route path={':org'}>
          <Route
            index
            path="dashboard"
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={OrgDashboardPage}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'profile-setting',
                      'first-project-modal',
                      'messagings',
                      'task-status',
                      'task-list',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'profile-settings',
                      'first-project-modals',
                      'messagings',
                      'task-statuses',
                      'task-lists',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          <Route
            path={'users/settings'}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={ProfileSettingAndPreferencePage}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'profile-setting',
                      'first-project-modal',
                      'task-list',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'profile-settings',
                      'first-project-modals',
                      'task-lists',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          <Route
            path={NavRoutesEnum.MESSAGES}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={MessagesWrapper}
                    pageSlug={['page-dashboard-left-panel', 'messaging']}
                    cmsPageName={['page-dashboard-left-panels', 'messagings']}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          <Route
            path={NavRoutesEnum.DASHBOARD_PROJECT_LIST}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={DashboardProjectListPage}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'project-list-page',
                      'page-project-list-right-panel',
                      'profile-setting',
                      'industry',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'project-list-pages',
                      'page-project-list-right-panels',
                      'profile-settings',
                      'industries',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          <Route
            path={NavRoutesEnum.DASHBOARD_PROJECT_DETAILS}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={ProjectDashboardPage}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'empty-project-dashboard',
                      'task-list',
                      'page-project-dashboard-right-panel',
                      'memopad',
                      'grid-list',
                      'profile-setting',
                      'customize-status-update-panel',
                      'messaging',
                      'task-status',
                      'archive-project',
                      'custom-field',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'empty-project-dashboards',
                      'task-lists',
                      'page-project-dashboard-right-panels',
                      'memopads',
                      'grid-lists',
                      'profile-settings',
                      'customize-status-update-panels',
                      'messagings',
                      'task-statuses',
                      'archive-projects',
                      'custom-fields',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          {/*New dashboard*/}
          <Route
            path={NavRoutesEnum.NEW_DASHBOARD_PROJECT_DETAILS}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={NewProjectDashboardPage}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'empty-project-dashboard',
                      'task-list',
                      'page-project-dashboard-right-panel',
                      'memopad',
                      'grid-list',
                      'profile-setting',
                      'customize-status-update-panel',
                      'messaging',
                      'task-status',
                      'archive-project',
                      'custom-field',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'empty-project-dashboards',
                      'task-lists',
                      'page-project-dashboard-right-panels',
                      'memopads',
                      'grid-lists',
                      'profile-settings',
                      'customize-status-update-panels',
                      'messagings',
                      'task-statuses',
                      'archive-projects',
                      'custom-fields',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          {/* Temp route for UI dev */}
          <Route
            path={NavRoutesEnum.DASHBOARD_PROJECT_DETAILS + '/compact-view'}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={CompactViewWrapper}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'empty-project-dashboard',
                      'task-list',
                      'page-project-dashboard-right-panel',
                      'memopad',
                      'grid-list',
                      'profile-setting',
                      'customize-status-update-panel',
                      'messaging',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'empty-project-dashboards',
                      'task-lists',
                      'page-project-dashboard-right-panels',
                      'memopads',
                      'grid-lists',
                      'profile-settings',
                      'customize-status-update-panels',
                      'messagings',
                    ]}
                  />
                  <AnalyticsEventTracker />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
          <Route
            path={NavRoutesEnum.PLANNER}
            element={
              <AuthenticationGuard
                component={PlannerWrapperPage}
                pageSlug={['page-dashboard-left-panel', 'planner']}
                cmsPageName={['page-dashboard-left-panels', 'planners']}
              />
            }
          />
          <Route
            path={`${
              NavRoutesEnum.DASHBOARD_PROJECT_DETAILS +
              NavRoutesEnum.USER_STORY_GENERATOR
            }`}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AuthenticationGuard
                  component={UserStoryGenerator}
                  pageSlug={[
                    'page-dashboard-left-panel',
                    'profile-setting',
                    'user-story',
                  ]}
                  cmsPageName={[
                    'page-dashboard-left-panels',
                    'profile-settings',
                    'user-stories',
                  ]}
                />
              </React.Suspense>
            }
          />
          <Route
            path={`${
              NavRoutesEnum.DASHBOARD_PROJECT_DETAILS +
              NavRoutesEnum.EPIC_STORY_GENERATOR
            }`}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AuthenticationGuard
                  component={EpicAndStory}
                  pageSlug={[
                    'page-dashboard-left-panel',
                    'profile-setting',
                    'story-list',
                    'messaging',
                  ]}
                  cmsPageName={[
                    'page-dashboard-left-panels',
                    'profile-settings',
                    'story-lists',
                    'messagings',
                  ]}
                />
              </React.Suspense>
            }
          />
          <Route
            path={`${
              NavRoutesEnum.DASHBOARD_PROJECT_DETAILS +
              NavRoutesEnum.ARCHIVE_EPIC
            }`}
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AuthenticationGuard
                  component={ArchiveEpic}
                  pageSlug={[
                    'page-dashboard-left-panel',
                    'profile-setting',
                    'story-list',
                    'messaging',
                  ]}
                  cmsPageName={[
                    'page-dashboard-left-panels',
                    'profile-settings',
                    'story-lists',
                    'messagings',
                  ]}
                />
              </React.Suspense>
            }
          />
          {/* Temp Route For PnC Related Task */}
          <Route
            path="project/:projectId/pnc/*"
            element={
              <React.Suspense fallback={<FullPageLoader />}>
                <AnalyticsProvider>
                  <AuthenticationGuard
                    component={PointAndComment}
                    pageSlug={[
                      'page-dashboard-left-panel',
                      'empty-project-dashboard',
                      'task-list',
                      'page-project-dashboard-right-panel',
                      'pnc-feeback-board',
                      'archive-project',
                      'pnc-add-environment',
                    ]}
                    cmsPageName={[
                      'page-dashboard-left-panels',
                      'empty-project-dashboards',
                      'task-lists',
                      'page-project-dashboard-right-panels',
                      'pnc-feeback-boards',
                      'archive-projects',
                      'pnc-add-environments',
                    ]}
                  />
                </AnalyticsProvider>
              </React.Suspense>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
