import React, { useState } from 'react';
import {
  ICustomFieldModalAttributes,
  ICustomFieldState,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import AddTask from '../AddTask';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import Table from './TableSection/Table';

enum TaskColumnSortIds {
  TASK_STATUS = 'task_status_id',
  TASK_PRIORITY = 'task_priority_id',
  TASK_DUE_DATE = 'due_date',
  TASK_NAME = 'task_name',
  TASK_ASSIGNEE = 'assignee_name',
  TASK_UPDATED_ON = 'updated_on',
}

enum TaskColumnSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

const TaskListDetails: React.FC<{
  taskDetails?: ITaskList;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  sectionToggleState: 'collapsed' | 'expanded';
  socket?: any;
  setShowSidePanelForCustomField: React.Dispatch<
    React.SetStateAction<ICustomFieldState>
  >;
  getSectionDetails: () => Promise<void>;
  isTaskEmpty?: boolean;
  customfieldCmsData?: ICustomFieldModalAttributes;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  taskDetails,
  setTaskListDetails,
  sectionToggleState,
  // socket,
  setShowSidePanelForCustomField,
  getSectionDetails,
  isTaskEmpty,
  customfieldCmsData,
  setIsTaskEmpty,
}) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const [sortOptions, setSortOptions] = useState(() => {
    const x = taskDetails?.task_list_details?.filter(
      (eachTask) => eachTask.due_date !== null,
    )?.length;
    if (!x) {
      return {
        sortOrder: TaskColumnSortOrder.ASC,
        sortColumn: TaskColumnSortIds.TASK_UPDATED_ON,
      };
    }
    return {
      sortOrder: TaskColumnSortOrder.DESC,
      sortColumn: TaskColumnSortIds.TASK_DUE_DATE,
    };
  });

  // Functions
  const sortTasks = (sortColumn: TaskColumnSortIds) => {
    setSortOptions((prev) => ({
      sortColumn: sortColumn,
      sortOrder:
        prev.sortOrder === TaskColumnSortOrder.ASC
          ? TaskColumnSortOrder.DESC
          : TaskColumnSortOrder.ASC,
    }));
    const _task_list_details = taskDetails?.task_list_details;
    _task_list_details?.sort((a: any, b: any) => {
      // equal items sort equally
      if (a[sortColumn] === b[sortColumn]) {
        return 0;
      }

      // nulls sort after anything else
      if (a[sortColumn] === null) {
        return 1;
      }
      if (b[sortColumn] === null) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (sortOptions.sortOrder === TaskColumnSortOrder.ASC) {
        return a[sortColumn] < b[sortColumn] ? -1 : 1;
      }

      // if descending, highest sorts first
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    });

    setTaskListDetails((prev) => ({
      ...prev,
      task_list_details: _task_list_details,
    }));
  };

  const getSortIconClass = (
    sortColumn: TaskColumnSortIds,
    sortOrder: TaskColumnSortOrder,
  ) => {
    if (sortOptions.sortColumn === sortColumn) {
      if (sortOptions.sortOrder === TaskColumnSortOrder.DESC) {
        return 'sort-arrow-up';
      } else {
        return 'sort-arrow-down';
      }
    } else {
      return 'double-sort-arrow';
    }
  };
  return (
    <div className={` `}>
      <Table
        customfieldCmsData={customfieldCmsData}
        isTaskEmpty={isTaskEmpty}
        setIsTaskEmpty={setIsTaskEmpty}
        taskDetails={taskDetails}
        sectionToggleState={sectionToggleState}
        // socket={socket}
        setTaskListDetails={setTaskListDetails}
      />
      <Rbac
        allowedPermissions={[ERbacPermissions.PROJECT_SECTION_TASK_CREATE]}
        project_role_id={projectDetails?.associated_role_id}
      >
        {!projectDetails?.is_archived ? (
          <AddTask
            taskDetails={taskDetails}
            setTaskListDetails={setTaskListDetails}
          />
        ) : (
          <></>
        )}
      </Rbac>
    </div>
  );
};

export default TaskListDetails;
