import { Input, Tooltip } from "antd";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { IGridListBlockDetail, IProjectState } from "models/interface";
import React, { useEffect, useMemo, useState } from "react";
import { useProjectDashboardStore } from "stores";
import { isEmptyString } from "utils/commonFunctions";

interface CustomNumberFieldProps {
  value: number;
  disabled?: boolean;
  configuration?: any;
  taskDetail: IGridListBlockDetail;
  onChange: (value: any) => void;
}
const getUnit = (configuration: any, direction: number) => {
  const unit = configuration?.number_format
    ?.find((u: any) => u.id === configuration?.selectedFormat)?.description ?? '';
  return (configuration.direction === direction && !!unit) ? unit : '';
};
const formatNegativeString = (numStr: string): string => {
  const dashCount = (numStr.match(/-/g) || []).length;// Count the number of dashes in the string
  const cleanedStr = numStr.replace(/-/g, ''); // Remove all dashes from the string
  const isNegative = dashCount % 2 === 1; // Determine if the number should be negative or positive based on the dash count
  return isNegative ? `-${cleanedStr}` : cleanedStr; // Add a dash at the beginning if it should be negative
};
const getNumber = (value: string | number) => (String(value)?.replace(/[^0-9.-]/g, ''));
const formatNumber = (number: string, precision: number = 0, grouping: boolean = true): string => {
  const parts = number.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = precision ? '.' + String(parts?.[1] ?? '').padEnd(precision, '0').substring(0, precision) : '';
  const formattedIntegerPart = grouping ? integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : integerPart;
  return formattedIntegerPart + decimalPart;
}
const isValidNumber = (value: any): boolean => {
  return value !== null && value !== undefined && value !== '-' && !isNaN(Number(value));
};


const CustomNumberField: React.FC<CustomNumberFieldProps> = (props) => {
  let { value: initialValue, disabled = false, configuration, taskDetail, onChange } = props;
  const [value, setValue] = useState<string>(
    isValidNumber(initialValue)
      ? `${initialValue}`
      : '');
  const [editing, setEditing] = useState(false);

  const {
    projectDetails,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const negativeConfig =
    configuration?.negative_number_format
      ?.find((ele: any) => ele.id === configuration?.selectedNegativeNumberFormat) || null
  const isNegative = useMemo(() => Number(getNumber(value)) < 0, [value])
  const numberAlignment = configuration?.alignments?.find((a: any) => a?.id === configuration?.alignment)?.value || 'right';

  useEffect(() => {
    setValue(isValidNumber(initialValue)
      ? `${initialValue}`
      : '')
  }, [initialValue]);

  const formattedValue = () => {
    const prefix = getUnit(configuration, 1)
    const suffix = getUnit(configuration, 2)
    const valueText = String(value)?.replace(/[^0-9.]/g, '')
    if (!valueText) return ''

    let formattedNumber = `${formatNumber(valueText, configuration?.precision)}`;

    if (negativeConfig?.signed && isNegative) {
      formattedNumber = `-${formattedNumber}`
    }
    if (negativeConfig?.brackets && isNegative) {
      formattedNumber = `(${formattedNumber})`
    }
    formattedNumber = `${prefix} ${formattedNumber} ${suffix}`.trim()
    return !value ? undefined : formattedNumber;
  }

  const updateExistingTask = async () => {
    setEditing(false)
    const getValueFromDetail = taskDetail?.custom_field_block_value;
    const initialNumber = isValidNumber(getValueFromDetail)
      ? getNumber(getValueFromDetail)
      : null;
    const currentNumber = value && isValidNumber(getNumber(value))
      ? getNumber(value)?.replace(/^0+(?=\d)/, '')
      : null;
    if (initialNumber === currentNumber) {
      setValue(isValidNumber(initialValue)
        ? `${initialValue}`
        : '');
      return;
    };
    if ((!isValidNumber(initialNumber) && !isValidNumber(currentNumber))) {
      setValue('');
      return;
    }
    let tempValue = value === '-' ? '' : value;
    setValue(tempValue?.replace(/^0+(?=\d)/, ''))
    onChange(currentNumber)
  };

  const enableEditing = () => {
    setEditing(true);
    setValue(formatNumber(value, configuration.precision, false))
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let content = inputValue;
    content = formatNegativeString(content);
    let regex = /[^0-9.-]/g
    const allowedNegative = !!negativeConfig?.brackets || !!negativeConfig?.signed
    const allowedDecimal = configuration?.precision && configuration?.precision > 0
    if (!allowedDecimal && allowedNegative) {
      regex = /[^0-9-]/g
    }
    if (!allowedDecimal && !allowedNegative) {
      regex = /[\D]/g
    }
    if (allowedDecimal && !allowedNegative) {
      regex = /[^0-9.]/g
    }
    if (inputValue.startsWith('.') && configuration?.precision > 0) {
      content = '0' + inputValue;
    } else if (inputValue) {
      content = content?.replace(regex, '')
      const pattern = /^([^.]*)\.([^.]*)\.(.*)$/;
      content = content?.replace(pattern, '$1.$2$3');// Replace the second dot with nothing
      const dotIndex = content.indexOf('.');
      const addition = (configuration.precision || 0) + 1
      if (dotIndex !== -1 && dotIndex + addition < content.length) {
        content = content.substring(0, dotIndex + addition);
      }
    }
    setValue(content)
  }

  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  )

  const renderInput = allowedToEdit ? (
    <Input
      autoFocus={editing}
      value={value}
      onBlur={updateExistingTask}
      onFocus={() => setEditing(true)}
      disabled={disabled}
      onChange={onValueChange}
      onPressEnter={updateExistingTask}
      style={{
        boxShadow: 'none',
        textAlign: numberAlignment,
        height: 32
      }} />
  ) : null
  return !editing && !isEmptyString(value) ? (
    <div style={{
      width: '100%',
      height: 32,
      display: 'flex',
      alignItems: 'center'
    }}>
      <div role="button"
        className="taskNameBlk"
        style={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          textAlign: numberAlignment,
          width: '100%',
        }} onClick={
          () => {
            (allowedToEdit && !disabled) && enableEditing()
          }
        }>
        <Tooltip color="#2E364C" title={editing ? undefined : formattedValue()} destroyTooltipOnHide placement="left">
          <span
            className="simpleTaskListTaskName"
            style={{ color: isNegative ? negativeConfig?.colour : undefined, }} >{formattedValue()}</span>
        </Tooltip>
      </div>
    </div>
  )
    : renderInput;
};

export default CustomNumberField;


