import { FC, useEffect, useState } from 'react';
import Accordion from './Accordion';
import {
  IFeedbackDetailsData,
  IPointAndCommentRightDrawerProps,
  IResponseGetFeedbackbyId,
} from 'models/interface/pnc.interface';
import feedbackService from 'services/pncService/feedback.service';
import {
  collectFullNames,
  convertNamesToInitials,
  formatDateTime,
} from 'utils/commonFunctions';
import FeedBackBubble from './FeedBackBubble';
import { message, Select, Skeleton } from 'antd';
import FeedbackStatus from './FeedbackStatus';

import { IProjectState } from 'models/interface';
import VideoPlayer from './VideoPlayer';
import { useProjectDashboardStore } from 'stores';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import FeedbackMessageBox from './FeedbackMessageBox';
import { ITaskPriorityFilter, useFeedbackStore } from 'stores/feedback.store';

const PointAndCommentRightDrawer: FC<IPointAndCommentRightDrawerProps> = ({
  show,
  setShow,
  feedbackId,
  fbDetailsId,
}) => {
  const [screenDetailsData, setScreenDetailsData] = useState<any>();
  const [feedbackData, setFeedbackData] = useState<IFeedbackDetailsData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();
  const { taskPriorityFilters } = useFeedbackStore((state: any) => state);

  const getFeedbackData = async (feedbackDetailsId: number) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackDetailsId,
    };
    try {
      await handleFetchScreenDetails();
      const response: IResponseGetFeedbackbyId =
        await feedbackService.getFeedbacksById(payload);
      if (response?.messageId === 1) {
        setFeedbackData(response?.data?.feedback_data);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
        );
        console.log(response?.message);
        setShow(false);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
      );
      setShow(false);
    } finally {
      setIsLoading(false);
    }
  };

  const severityItems = taskPriorityFilters
    ?.filter(
      (i: ITaskPriorityFilter) =>
        i.task_priority_id !== feedbackData[0]?.severity,
    )
    .map((i: ITaskPriorityFilter) => ({
      value: i.task_priority_id,
      label: i.name,
    }));

  const handleFetchScreenDetails = async () => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      feedbackId: feedbackId,
    };
    try {
      const response: any = await feedbackService.screenDetails(payload);
      if (response.messageId === 1) {
        setScreenDetailsData(response.data[0]);
      } else {
        console.log(response);
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_by_id,
        );
        setShow(false);
      }
    } catch (error) {
      console.log(error);
      setShow(false);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_by_id,
      );
    }
  };

  const handleSeverity = async (value: string) => {
    setFeedbackData([{ ...feedbackData[0], severity: Number(value) }]);
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackData[0]?.details_id,
      severityId: value,
    };
    try {
      const response: any = await feedbackService.updateSeverity(payload);
      if (response?.messageId !== 1) {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_severity,
        );
        console.log(response);
        setFeedbackData([
          { ...feedbackData[0], severity: feedbackData[0]?.severity },
        ]);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_severity,
      );
      setFeedbackData([
        { ...feedbackData[0], severity: feedbackData[0]?.severity },
      ]);
    }
  };

  useEffect(() => {
    getFeedbackData(fbDetailsId);
  }, []);

  const getSeverityClassName = () => {
    switch (feedbackData[0]?.severity) {
      case 2:
        return 'noSeverityValue';
      case 3:
        return 'severityMedium';
      case 4:
        return 'severityLow';
      case 5:
        return 'severityHigh';
      default:
        return 'noSeverityValue';
    }
  };

  return (
    <div
      className={
        show
          ? 'createNewProjectForm drawerRight showDrawer'
          : 'createNewProjectForm drawerRight'
      }
    >
      <div className="drawerContainer">
        <div className="header">
          <h2>
            {pncFeedbackSpecificCmsData?.feedback_popup_heading} - #
            {feedbackData[0]?.details_id}
          </h2>
          <div className="flxCenter">
            <div className="drawerHeadRight">
              <span className="material-icons-outlined">language</span>
              <a
                href={screenDetailsData?.page_url}
                target="_blank"
                rel="noreferrer"
              >
                {pncFeedbackSpecificCmsData?.right_drawer_open_page}
              </a>
            </div>
            <span
              onClick={() => setShow(!show)}
              className="material-icons-outlined close"
            >
              close
            </span>
          </div>
        </div>
        <div className="formContent contentScroll">
          {isLoading ? (
            <>
              <Skeleton.Input size="large" block={true} />
              <br />
              <Skeleton.Input size="large" block={true} />
              <br />
              <Skeleton.Input size="large" block={true} />
              <br />
              <Skeleton.Input size="large" block={true} />
            </>
          ) : (
            <table className="detailsTable">
              <tbody>
                <tr>
                  <th>{pncFeedbackSpecificCmsData?.lbl_right_drawer_status}</th>
                  <td className="withImg">
                    <FeedbackStatus
                      feedbacId={feedbackId}
                      feedBackStatusValue={feedbackData[0]?.feedback_status_id.toString()}
                      setFeedbackData={setFeedbackData}
                      feedbackData={feedbackData}
                      detailsId={feedbackData[0]?.details_id}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    {pncFeedbackSpecificCmsData?.lbl_right_drawer_project}
                  </th>
                  <td>{projectDetails?.project_name}</td>
                </tr>
                <tr>
                  <th>
                    {pncFeedbackSpecificCmsData?.lbl_right_drawer_assigned_by}
                  </th>
                  <td>
                    {feedbackData.length > 0
                      ? feedbackData[0]?.full_name
                      : 'N/A'}
                  </td>
                </tr>
              </tbody>
            </table>
          )}

          <div className="accordionMainWrap">
            {isLoading ? (
              <>
                <Skeleton.Image active />
                <br />
                <br />
              </>
            ) : (
              <div className="nonCollapsible">
                <label className="nonCollapsibleTitle">
                  {pncFeedbackSpecificCmsData?.lbl_right_drawer_subheading_ss}
                </label>
                <div className="imageWithBubble">
                  {screenDetailsData?.screenshot_url.includes('png') ? (
                    <img
                      className="dBlock"
                      src={screenDetailsData?.screenshot_url}
                      alt=""
                    />
                  ) : (
                    screenDetailsData?.screenshot_url && (
                      <VideoPlayer src={screenDetailsData?.screenshot_url} />
                    )
                  )}
                  {feedbackData[0]?.is_deleted === false &&
                    screenDetailsData?.screenshot_url.includes('.png') && (
                      <div
                        className="markerForFeedback"
                        style={{
                          top: `${feedbackData[0]?.feedback_coordinates?.top}%`,
                          left: `${feedbackData[0]?.feedback_coordinates?.left}%`,
                          width: `${feedbackData[0]?.feedback_coordinates?.width}%`,
                          height: `${feedbackData[0]?.feedback_coordinates?.height}%`,
                        }}
                      >
                        {feedbackData?.map((ele: any) => {
                          const fullNames = collectFullNames(ele);
                          const initialNameArray =
                            convertNamesToInitials(fullNames);
                          const isResolved = ele?.feedback_status_id === 3;
                          return (
                            <FeedBackBubble
                              key={ele?.details_id}
                              positionCordinate={{
                                top: `-44px`,
                                left: `100%`,
                              }}
                              initialNameArray={initialNameArray}
                              isResolved={isResolved}
                              feedbackDetailsId={ele?.details_id}
                              type={'static'}
                            />
                          );
                        })}
                      </div>
                    )}
                </div>
              </div>
            )}
            <Accordion
              title={`${pncFeedbackSpecificCmsData?.lbl_right_drawer_subheading_metadata}`}
            >
              {isLoading ? (
                <>
                  <Skeleton.Input active size="large" block={true} />
                  <br />
                  <br />
                  <Skeleton.Input active size="large" block={true} />
                  <br />
                  <br />
                  <Skeleton.Input active size="large" block={true} />
                  <br />
                  <br />
                  <Skeleton.Input active size="large" block={true} />
                </>
              ) : (
                <table className="detailsTable">
                  <tr>
                    <th>
                      {pncFeedbackSpecificCmsData?.lbl_right_drawer_reported_on}
                    </th>
                    <td>{`${formatDateTime(
                      screenDetailsData?.feedback_on,
                    )} (UTC)`}</td>
                  </tr>
                  <tr>
                    <th>
                      {pncFeedbackSpecificCmsData?.lbl_right_drawer_severity}
                    </th>
                    <td>
                      <Select
                        onChange={handleSeverity}
                        value={`${
                          taskPriorityFilters.filter(
                            (i: ITaskPriorityFilter) =>
                              i.task_priority_id === feedbackData[0]?.severity,
                          )[0]?.name || 'Select'
                        }`}
                        options={severityItems}
                        className={`severityDropdown ${getSeverityClassName()}`}
                        disabled={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{pncFeedbackSpecificCmsData?.lbl_right_drawer_env}</th>
                    <td>
                      <div className="environmentTblWrap">
                        <table className="detailsTable environmentTbl">
                          <tr>
                            <th>
                              {
                                pncFeedbackSpecificCmsData?.lbl_right_drawer_env_browser
                              }
                            </th>
                            <td>{screenDetailsData?.browser}</td>
                          </tr>
                          <tr>
                            <th>
                              {
                                pncFeedbackSpecificCmsData?.lbl_right_drawer_env_os
                              }
                            </th>
                            <td>{screenDetailsData?.operating_system}</td>
                          </tr>
                          <tr>
                            <th>
                              {
                                pncFeedbackSpecificCmsData?.lbl_right_drawer_env_viewport
                              }
                            </th>
                            <td>
                              {screenDetailsData?.screen_width} x{' '}
                              {screenDetailsData?.screen_height}
                            </td>
                          </tr>
                          <tr>
                            <th>
                              {
                                pncFeedbackSpecificCmsData?.lbl_right_drawer_env_pixel_ratio
                              }
                            </th>
                            <td>16:9</td>
                          </tr>
                          <tr>
                            <th>
                              {
                                pncFeedbackSpecificCmsData?.lbl_right_drawer_env_zoom
                              }
                            </th>
                            <td>{screenDetailsData?.browser_zoom}%</td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                </table>
              )}
            </Accordion>
            <Accordion
              title={`${pncFeedbackSpecificCmsData?.lbl_right_drawer_subheading_messages}`}
            >
              <FeedbackMessageBox
                feedbackDetailsId={fbDetailsId}
                feedbackId={feedbackId}
                handleCancel={() => setShow(false)}
                showHeader={false}
                autoFocus={false}
                type={
                  screenDetailsData?.screenshot_url.includes('png')
                    ? 'image'
                    : 'video'
                }
                feedbackData={feedbackData}
                setFeedbackData={setFeedbackData}
                isLoading={isLoading}
              />
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointAndCommentRightDrawer;
