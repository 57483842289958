import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Input, Button, Popconfirm, Popover } from 'antd';
import { RightOutlined, PauseOutlined } from '@ant-design/icons';
import { getContrastColor } from 'utils/commonFunctions';
import { IProjectState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';

interface StatusOptionsCreatorProps {
  options: any[];
  onChange: (updatedOptions: any) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
}

interface Status {
  id: string;
  name: string;
  color: string;
}

const paletteColors = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#A2CFFE',
  '#98FF98',
  '#E6E6FA',
  '#FFE5B4',
  '#FBF5C2',
  '#D0F0C0',
  '#C8A2C8',
  '#FFCBA4',
  '#C1C6FC',
  '#B6D0E2',
  '#D8BFD8',
  '#FDFD96',
  '#F4C2C2',
  '#87CEEB',
  '#FDD5B1',
  '#FF5733',
  '#FFC300',
  '#DAF7A6',
  '#33FFBD',
  '#581845',
  '#FF3385',
];

const defaultColors = ['#FFFFFF', '#4B8DFF', '#FBA63C'];

const StatusOptionsCreator: React.FC<StatusOptionsCreatorProps> = (props) => {
  const { onChange, options, onDeleteOptions } = props;
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [focusingInput, setFocusingInput] = useState<string | null>(null);
  const { customfieldCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  useEffect(() => {
    if (options && !statusList.length) {
      let tempStatus: Status[] = [];

      options.forEach((option: any) => {
        tempStatus.push({
          id: Math.random().toString(),
          name: option.value,
          color: option.color,
        });
      });

      setStatusList(tempStatus);
    }
  }, [options]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(statusList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setStatusList(items);
    onChange(items);
  };

  const handleStatusNameChange = (id: string, newName: string) => {
    const updatedList = statusList.map((status) =>
      status.id === id ? { ...status, name: newName } : status,
    );
    setFocusingInput(id);

    setStatusList(updatedList);
    onChange(updatedList);
  };

  const handleColorChange = (id: string, newColor: string) => {
    const updatedList = statusList.map((status) =>
      status.id === id ? { ...status, color: newColor } : status,
    );

    setStatusList(updatedList);
    onChange(updatedList);
  };

  const handleDeleteStatus = (id: string) => {
    const deleteOptions = statusList.filter((status) => status.id === id);
    const updatedList = statusList.filter((status) => status.id !== id);

    setStatusList(updatedList);
    onChange(updatedList);
    onDeleteOptions?.(deleteOptions);
  };

  const handleAddStatus = () => {
    const newStatus: Status = {
      id: Date.now().toString(),
      name: '',
      color: '#FFFFFF',
    };

    setStatusList([...statusList, newStatus]);
    onChange([...statusList, newStatus]);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="statusList">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {statusList.map((status, index) => (
              <Draggable key={status.id} draggableId={status.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                    className="controlOptionRow"
                  >
                    <PauseOutlined
                      {...provided.dragHandleProps}
                      className="dragHandle"
                    />
                    <Input
                      value={status.name}
                      onChange={(e) =>
                        handleStatusNameChange(status.id, e.target.value)
                      }
                      style={{
                        backgroundColor: status.color,
                        color: getContrastColor(status.color),
                      }}
                      autoFocus={true}
                      className="colorInput"
                    />
                    {statusList?.length >= 2 && (
                      <Popconfirm
                        title={
                          customfieldCmsData?.lbl_column_setting_popup_options
                            .lbl_delete_confirm_msg
                        }
                        okText={
                          customfieldCmsData?.lbl_column_setting_popup_options
                            .lbl_delete_ok_text
                        }
                        okButtonProps={{
                          style: { backgroundColor: '#ba1a1a' },
                          className: 'deleteFld'
                        }}
                        cancelText={
                          customfieldCmsData?.lbl_column_setting_popup_options
                            .lbl_delete_cancel_text
                        }
                        onConfirm={() => handleDeleteStatus(status.id)}
                      >
                        <span className="cmnIcon deleteBin"></span>
                      </Popconfirm>
                    )}
                    <Popover
                      content={
                        <div className="colorPallet">
                          {paletteColors.map((color) => (
                            <div
                              key={color}
                              style={{
                                backgroundColor: color,
                              }}
                              className="colorBlk"
                              onClick={() =>
                                handleColorChange(status.id, color)
                              }
                            />
                          ))}
                        </div>
                      }
                      trigger="click"
                      placement="right"
                    >
                      <div className="primaryColorOption">
                        {defaultColors.map((color) => (
                          <div
                            key={color}
                            style={{
                              backgroundColor: color,
                            }}
                            className="colorBlk"
                            onClick={(e) => {
                              e.stopPropagation();

                              handleColorChange(status.id, color);
                            }}
                          />
                        ))}
                        <Button type="text" icon={<RightOutlined />} />
                      </div>
                    </Popover>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button block onClick={handleAddStatus} className="newBlkBtn">
        <span className="addPlusSolid"></span>{' '}
        {customfieldCmsData?.lbl_column_setting_popup_options.lbl_add_more}
      </Button>
    </DragDropContext>
  );
};

export default StatusOptionsCreator;
