import { FC, useCallback, useEffect, useState } from 'react';
import FeedbackBoard from './FeedbackBoard/FeedbackBoardPage';
import '../../../assets/css/custom-pnc.css';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import AddEnvironmentPage from './AddProject/AddEnvironmentPage';
import PncHeader from './pncHeader';
import feedbackService from 'services/pncService/feedback.service';
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import { ComponentLoader } from 'components/sharedComponents';
import { useFeedbackStore } from 'stores/feedback.store';
import {
  getCurrentOrganization,
  getPageSpecificData,
} from 'utils/commonFunctions';
import {
  cmsService,
  dashboardService,
  loggerService,
  projectService,
} from 'services';
import BrowserStorageService from 'services/browserStorage.service';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import { message } from 'antd';
import { IApiResponse, IFolderData, IProjectState } from 'models/interface';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { useOrganizationRoutes } from 'routes/orgRoutes';

const PointAndComment: FC<{ pageSlug?: string[]; cmsPageName?: string[] }> = ({
  pageSlug,
  cmsPageName,
}) => {
  const [responseData, setResponseData] = useState<null | Array<any>>(null);
  const [isEnvDataLoaded, setIsEnvDataLoaded] = useState<boolean>(false);
  const {
    setFeedbackList,
    setEnvValue,
    setEnvFilterData,
    setTaskStatusFilters,
    setTaskPriorityFilters,
  } = useFeedbackStore((state: any) => state);
  const urlParams = useParams();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const { userDetails } = useUserStore((state: any) => state);
  const org_key = location.pathname.split('/')[2];
  const { loaderState, setLoaderState } = useLoaderStore();
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  const browserStorage = BrowserStorageService.getInstance();
  const {
    pncFeedbackSpecificCmsData,
    projectDetailsPageSpecificData,
    projectUpdatePageSpecificData,
    pncAddEnvSpecificCmsData,
    setPncFeedbackSpecificCmsData,
    setProjectDetailsPageSpecificData,
    setProjectUpdatePageSpecificData,
    setPncAddEnvSpecificCmsData,
  } = usePncCmsDataStore();
  const { setProjectDetails, fetchProjectMembers, setTaskListCmsData } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { folderList, setFolderList } = useDashboardStore(
    (state: any) => state,
  );
  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();

  const fetchEnvData = useCallback(async () => {
    setLoaderState('active');
    setFeedbackList([]);
    const payload = {
      organizationId: getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!,
      projectId: Number(urlParams?.projectId),
    };
    try {
      if (payload.organizationId && payload.projectId) {
        const response = await feedbackService.getEnvData(payload);
        if (response?.messageId === 1) {
          setResponseData(response?.data);
          setEnvFilterData(response?.data);
          if (response?.data.length > 0 && !urlSearchParams.get('env')) {
            setUrlSearchParams({ env: response.data[0].environment_id });
          }
        } else {
          console.log(response);
          message.error(
            pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_all_envs,
          );
        }
      }
    } catch (error) {
      console.log(error);
      message.error(pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_all_envs);
    } finally {
      setLoaderState('inactive');
    }
  }, [urlSearchParams.get('env'), org_key, urlParams?.projectId]);

  const getPageSpecificCmsData = useCallback(
    async (pageSlugInfo: string[]) => {
      let projectCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[1]);
      let newTaskListCmsData: any = getPageSpecificData(
        cmsData,
        pageSlugInfo[2],
      );
      let projectUpdateCmsData: any = getPageSpecificData(
        cmsData,
        pageSlugInfo[3],
      );
      let pncFeedbackCmsData: any = getPageSpecificData(
        cmsData,
        pageSlugInfo[4],
      );
      let pncAddEnvCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[6]);
      if (
        !browserStorage.getGlobalData() ||
        !projectCmsData ||
        !newTaskListCmsData ||
        !projectUpdateCmsData ||
        !pncFeedbackCmsData ||
        !pncAddEnvCmsData
      ) {
        setLoaderState('active');
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlugInfo,
        );
        projectCmsData = getPageSpecificData(cmsData, pageSlugInfo[1]);
        newTaskListCmsData = getPageSpecificData(cmsData, pageSlugInfo[2]);
        projectUpdateCmsData = getPageSpecificData(cmsData, pageSlugInfo[3]);
        pncFeedbackCmsData = getPageSpecificData(cmsData, pageSlugInfo[4]);
        pncAddEnvCmsData = getPageSpecificData(cmsData, pageSlugInfo[6]);
      }
      projectCmsData &&
        setProjectDetailsPageSpecificData(projectCmsData[0]?.attributes);
      newTaskListCmsData &&
        setTaskListCmsData(newTaskListCmsData[0]?.attributes);
      projectUpdateCmsData &&
        setProjectUpdatePageSpecificData(projectUpdateCmsData[0]?.attributes);
      pncFeedbackCmsData &&
        setPncFeedbackSpecificCmsData(pncFeedbackCmsData[0]?.attributes);
      pncAddEnvCmsData &&
        setPncAddEnvSpecificCmsData(pncAddEnvCmsData[0]?.attributes);
      setLoaderState('inactive');
    },
    [
      browserStorage,
      cmsData,
      cmsPageName,
      setCmsData,
      setLoaderState,
      setPncFeedbackSpecificCmsData,
      setProjectDetailsPageSpecificData,
      setProjectUpdatePageSpecificData,
      setTaskListCmsData,
      setPncAddEnvSpecificCmsData,
    ],
  );

  const fetchPncFilters = async () => {
    const payload = {
      organizationId: getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!,
      projectId: Number(urlParams?.projectId),
    };
    try {
      if (payload.organizationId && payload.projectId) {
        const response = await feedbackService.getPncFilters(payload);
        if (response?.messageId === 1) {
          setTaskStatusFilters(response?.data?.task_status_filter);
          setTaskPriorityFilters(response?.data?.task_priority_filter);
        } else {
          console.log(response);
          message.error(
            pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_pnc_filters,
          );
        }
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_pnc_filters,
      );
    } finally {
      setLoaderState('inactive');
    }
  };

  useEffect(() => {
    (async () => {
      if (
        !projectDetailsPageSpecificData &&
        !projectUpdatePageSpecificData &&
        !pncFeedbackSpecificCmsData &&
        !pncAddEnvSpecificCmsData &&
        pageSlug
      ) {
        await getPageSpecificCmsData(pageSlug);
      }
    })();
  }, [
    projectDetailsPageSpecificData,
    projectUpdatePageSpecificData,
    pageSlug,
    getPageSpecificCmsData,
    pncFeedbackSpecificCmsData,
    pncAddEnvSpecificCmsData,
  ]);

  const fetchFolderList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
      };

      let response: IApiResponse = await dashboardService.getFolder(payload);
      let folders: IFolderData[] = response.data.folders as IFolderData[];

      if (folders) {
        setFolderList(folders);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const getProjectDetails = async (project_id: string) => {
    // setLoaderState('active');
    try {
      const response = await projectService.getProjectDetails({
        project_id: project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
      });
      if (response.messageId === 1) {
        setProjectDetails(response.data);
        fetchProjectMembers({
          project_id: urlParams.projectId ?? '',
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
        });
      } else {
        navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      }
    } catch (error) {
      navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch project details',
        payload: { project_id: project_id },
      });
    }
    // finally {
    //   setLoaderState('inactive');
    // }
  };

  useEffect(() => {
    fetchEnvData();
    setIsEnvDataLoaded(true);
  }, []);

  useEffect(() => {
    fetchPncFilters();
  }, []);

  useEffect(() => {
    if (urlSearchParams.get('env')) {
      setEnvValue(Number(urlSearchParams.get('env')));
    }
  }, [urlSearchParams.get('env')]);

  useEffect(() => {
    (async () => {
      if (urlParams.projectId) {
        await getProjectDetails(urlParams.projectId);
        if (folderList?.length === 0) {
          await fetchFolderList();
        }
      }
    })();
  }, [urlParams]);

  return (
    <div className="mainContent mainContentPnc">
      <PncHeader cmsData={cmsData} getProjectDetails={getProjectDetails} />
      <ComponentLoader isLoading={loaderState === 'active'}>
        {!responseData ? (
          <></>
        ) : (
          <Routes>
            <Route
              path="feedback-board"
              element={
                responseData && responseData.length === 0 ? (
                  <Navigate
                    to={`/org/${urlParams?.org}/project/${urlParams?.projectId}/pnc/add-environment`}
                  />
                ) : (
                  <ReactFlowProvider>
                    <FeedbackBoard />
                  </ReactFlowProvider>
                )
              }
            />
            <Route path="add-environment" element={<AddEnvironmentPage />} />
          </Routes>
        )}
      </ComponentLoader>
    </div>
  );
};

export default PointAndComment;
