import { Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { getContrastColor } from 'utils/commonFunctions';

interface IPriorityProps {
  options: any[];
  value: string;
  onChange: (value: any) => void;
  onSelect: (value: any) => void;
  disabled?: boolean;
}

const CustomDropdown: React.FC<IPriorityProps> = ({
  options,
  value,
  onChange,
  onSelect,
  disabled = false,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = React.useRef<any>(null);
  const currentColor = options.find((i) => i.value === value)?.color ?? '';
  const isExist = options.find((i) => i.value === value);

  const handleChange = (val: string) => {
    onChange(val);
    setIsEdit(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !isDropdownOpen
      ) {
        setIsEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isDropdownOpen]);

  return (
    <div id="selectWrap" ref={selectRef}>
      {isEdit || !value || !isExist ? (
        <Select
          style={{
            width: '100%',
          }}
          disabled={disabled}
          options={options}
          popupClassName="customSelect"
          open={isDropdownOpen}
          // defaultValue={currentValue}
          value={!isExist ? undefined : value}
          onChange={handleChange}
          onSelect={onSelect}
          labelRender={(option) => {
            return (
              <span style={{ background: option.value }}>{option.value}</span>
            );
          }}
          optionRender={(option, { index }) => {
            const color = options[index].color;
            const value = option.data.value;
            return (
              <Tooltip title={value} placement="left" color={'#2E364C'}>
                <div
                  className="customOption"
                  style={{
                    background: color,
                    color: getContrastColor(color),
                  }}
                  title=""
                >
                  {option.value}
                </div>
              </Tooltip>
            );
          }}
          onDropdownVisibleChange={(open) => {
            setIsDropdownOpen(open);
            if (!open) setIsEdit(false);
          }}
        />
      ) : (
        <div
          onClick={() => {
            if (!disabled) {
              setIsEdit(true);
              setIsDropdownOpen(true);
            }
          }}
          className="statusCellValue"
          style={{
            background: currentColor,
            color: getContrastColor(currentColor),
          }}
        >
          <Tooltip
            title={value}
            placement="left"
            color={'#2E364C'}
          >
            {value}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
